monitoringApp.controller('monitoringPostModalCtrl', function(
    $scope,
    $rootScope,
    data,
    monitoringService,
    documentsService,
    $uibModalInstance
) {

    $scope.data = {
        ...data,
        title: data.title || '',
        message: data.description || '',
    };
    $scope.uploadedFiles = data.uploadedFiles || [];
    $scope.showProgress = null;

    $scope.files = [];

    $scope.submit = function() {
        $scope.disablePublishBtn = true;

        function uploadToDocumentService(filesList) {
            return new Promise((resolve) => {
                if (filesList.length) {
                    $scope.showProgress = gettext('Завантажуються файли');
                    return documentsService.uploadToDocumentService(filesList)
                        .then((documents) => {
                            resolve(documents);
                        });
                }
                return resolve()
            })
        }

        function createPost(tenderId, monitoring_id, title, description, documents, relatedPost) {
            return new Promise((resolve, reject) => {
                let requestData = {
                    title,
                    description,
                };

                if (relatedPost) {
                    requestData.relatedPost = relatedPost;
                }

                if (documents) {
                    requestData.documents = documents;
                }

                return monitoringService.newPost(tenderId, monitoring_id, requestData)
                    .then((res) => {
                        resolve(res)
                    });
            });

        }

        function addDocumentsToPost(tenderId, monitoring_id, post_id, documents) {
            if (!documents) {
                return null;
            }
            // Upload posts documents
            let promises = [];
            for (const doc of documents) {
                promises.push(monitoringService.addPostDocument(tenderId, monitoring_id, post_id, doc))
            }
            return Promise.all(promises).then(() => {
                return null;
            });
        }

        uploadToDocumentService($scope.files)
            .then((documents) => {
                if (data.post_id) { //Edit post
                    $scope.showProgress = gettext('Оновлюється запис');

                    if (data.author !== 'tender_owner'){
                        return createPost(data.tenderId, data.monitoring_id, $scope.data.title, $scope.data.message, documents, data.post_id);
                    } else {
                        if (documents) {
                            return addDocumentsToPost(data.tenderId, data.monitoring_id, data.post_id, documents);
                        }
                        return null;
                    }
                } else { // New post
                    $scope.showProgress = gettext('Створюється запис');
                    return createPost(data.tenderId, data.monitoring_id, $scope.data.title, $scope.data.message, documents)
                }
            })
            .then((result) => {
                $uibModalInstance.close({
                    id: result.id || data.post_id,
                    documents: result ? result.documents : [],
                });
                $scope.showProgress = null;
            });
    };

    $scope.isDisabled = function() {
        let disabled = (!$scope.data.message.length && !$scope.files) || $scope.disablePublishBtn;
        if (!data.post_id) {
            return disabled || !$scope.data.title.length;
        }
        return disabled;
    };

    $scope.close = function() {
        $uibModalInstance.close();
    };

    $scope.deleteDoc = function(index) {
        $scope.files.splice(index, 1);
    };

    $scope.$watch('file', function(val) {
        if ($scope.file && $scope.file.length) {
            $scope.files = $scope.files.concat(val);
        }
    });

});