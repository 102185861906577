tenderView.controller('tenderViewContractCtrl', function (
    $scope,
    $state,
    $timeout,
    $rootScope,
    $uibModal,
    $stateParams,
    tenderData,
    temporaryData,
    contractingService
) {
    var tenderId = $state.params.id;
    $scope.tender = tenderData.data
    $scope.action = tenderData.action
    $scope.bid = tenderData.bid
    $scope.contracts = [];
    $scope.isWinners = false;


    $scope.getContracts = function() {
        this.tender.contracts.forEach((contract) => {
            contractingService.get(contract.id)
                .then(data => {
                    $scope.contracts.push(data.data.response)
                })
        })
    }

    $scope.isWinner = function (contract) {
        if($scope.tender.procurementMethodType === 'reporting'){

        }

        const winnerAward = $scope.tender.awards.find(
            (award) => award.id === contract.awardID)
        return $scope.bid && $scope.bid.id === winnerAward.bid_id && contract.status === 'pending'

    }

    $scope.closeBids = function (award_id, contract) {
        return $uibModal.open({
            templateUrl: "/static/scripts/modules/TenderView/views/modals/tender-view.close-bids.modal.html",
            controller: "tenderViewCloseBidsModalCtrl",
            resolve: {
                data: function () {
                    return {
                        tender: $scope.tender,
                        tenderId: $scope.tender.cdb_id,
                        awardId: award_id ? award_id : awardId,
                        contractId: contract.id ? contract.id : contractId,
                        contract: contract
                    };
                }
            },
            backdrop: 'static'
        }).result.then(function () {
            $timeout(function () {
                $state.reload();
            }, 2000);
        });
    };

    $scope.addInfo = function (contract, isWinner) {
        return $uibModal.open({
            templateUrl: "/static/scripts/modules/TenderView/views/modals/tender-view.add-info.html",
            controller: "tenderViewContractInfo",
            resolve: {
                data: function () {
                    return {
                        contract: contract,
                        isWinner: isWinner,
                    };
                }
            },
            backdrop: 'static'
        }).result.then(function () {
            $timeout(function () {
                $state.reload();
            }, 2000);
        });
    };

    $scope.getContracts();
});
