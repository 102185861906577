class TenderItemInput {
    constructor(
        MULTILINGUAL_PROCEDURES,
        $state,
        $scope,
        unitsService,
        tenderService,
        classifierService,
    ) {
        // console.log('init tenderItemInputs');
        this.MULTILINGUAL_PROCEDURES = MULTILINGUAL_PROCEDURES;
        this.state = $state;
        this.scope = $scope;
        this.units = [];
        unitsService.get()
            .then((data) => {
                this.units = data;
            });

        $scope.isMOZ = (item) => {
            return classifierService.isMOZ(item);
        };
        
        $scope.isINNRequired = (item) => {
            return classifierService.isINNRequired(item);
        };

        $scope.isGMDN = (item) => {
            return classifierService.isGMDN(item);
        };

        $scope.isUAROAD = (item) => {
            return classifierService.isUAROAD(item);
        };

        $scope.isPMT = (name) => tenderService.isProcurementMethodType({
            procurementMethodType: this.procurementMethodType}, name);

        $scope.checkDefense = () => {
            const tender = tenderService.currentTender()
            return tender.procuringEntity.kind === 'defense' && (!tender.value || !tender.value.amount || tender.value.amount < 0.01) && tender.procurementMethodType === 'reporting'
        }
    }

    $onInit() {
        this.multilingual = this.MULTILINGUAL_PROCEDURES.has(this.procurementMethodType);
    }
}

tenderEdit.component('tenderItemInput', {
    templateUrl: '/static/scripts/modules/TenderEdit/views/components/tender-item-inputs.html',
    controller: TenderItemInput,
    controllerAs: 'vm',
    bindings: {
        item: '=',
        itemIndex: '<?',
        procurementMethodType: '<',
        ngDisabled: '<',
        onDelete: '&',
    }
});
