tenderView.controller('tenderViewComplaintCtrl', function(
    $scope,
    $rootScope,
    $uibModal,
    $state,
    $stateParams,
    complaintService,
    documentsService,
    tenderData,
    ntFlash,
    formationService,
    tenderService,
    $http
    ) {
    //TODO: Hide functionality in provider, remove rootscope statechange handler


    $scope.isCompetitiveDialogue = tenderService.isCompetitiveDialogue($scope.tender) || tenderService.isCompetitiveDialogue2Stage($scope.tender);

    ntFlash.clean();

    var tenderId = $state.params.id;
    var procurementMethodType = tenderData.data.procurementMethodType;

    $scope.actions = tenderData.action;
    $scope.tender = tenderData.data;
    $scope.tenderId = $stateParams.id;
    $scope.isLimited = tenderData.data.procurementMethod === 'limited';

    $scope.isPMT = (name) => tenderService.isProcurementMethodType($scope.tender, name);
    $scope.isAboveThreshold = ['aboveThresholdUA','aboveThresholdEU','aboveThresholdUA.defense','simple.defense','closeFrameworkAgreementUA'].indexOf(tenderData.data.procurementMethodType) > -1;

    $scope.isMultilotTender = tenderData.data.lots && tenderData.data.lots.length ? true : false;

    $scope.hasUnanswered = false;

    $scope.lotTitlesDictionary = {};
    ($scope.tender.lots || []).forEach(lot => {
        $scope.lotTitlesDictionary[lot.id] = lot.title;
    });

    $scope.refreshList = function() {
        $scope.complaints = [];
        $scope.isBlockedTender = tenderService.isBlockedTender(tenderData.data);

        complaintService.getList($scope.tenderId)
            .then(function(data) {
                data.response.forEach(function(complaint) {
                    if(complaint.relatedLot){
                        complaint.relatesTo = 'lot';
                        complaint.relatedItem = complaint.relatedLot;
                    } else {
                        complaint.relatesTo = 'tender';
                        complaint.relatedItem = $scope.tenderId;
                    }
                    $scope.complaints.push(complaint);
                })

                // $scope.complaints = $scope.complaints.concat(data.response);
                $scope.complaints.forEach(complaint => {
                    formationService.setSignToItem(complaint);
                });
            });

        if (tenderData.data.awards) {
            tenderData.data.awards.forEach(function(award) {
                complaintService.getList($scope.tenderId, award.id)
                    .then(function(data) {
                        data.response.forEach(function(complaint) {
                            complaint.awardId = award.id;
                            complaint.relatesTo = 'award';
                            complaint.relatedItem = award.id;
                            complaint.bidId = award.bid_id;
                            formationService.setSignToItem(complaint);
                            $scope.complaints.push(complaint);
                        });
                    });
                if (award.previous_versions && award.previous_versions.length) {
                    award.previous_versions.forEach(function(prev) {
                        complaintService.getList($scope.tenderId, prev.id)
                            .then(function(data) {
                                data.response.forEach(function(complaint) {
                                    complaint.awardId = prev.id;
                                    complaint.bidId = prev.bid_id;
                                    complaint.relatesTo = 'award';
                                    complaint.relatedItem = award.id;
                                    formationService.setSignToItem(complaint);
                                    $scope.complaints.push(complaint);
                                });
                            });
                    });
                }
                if ($scope.isLimited) {
                    $scope.awardComplaintPeriod = award.complaintPeriod;
                    //console.log($scope.awardComplaintPeriod);
                }
            });
        }

        if (tenderData.data.qualifications) {
            tenderData.data.qualifications.forEach(function(qualification) {
                complaintService.getList($scope.tenderId, null, qualification.id)
                    .then(function(data) {
                        data.response.forEach(function(complaint) {
                            complaint.qualificationId = qualification.id;
                            complaint.bidId = qualification.bidID;
                            complaint.relatesTo = 'qualification';
                            complaint.relatedItem = qualification.id;
                            formationService.setSignToItem(complaint);
                            $scope.complaints.push(complaint);
                        });
                    });
                if (qualification.previous_versions && qualification.previous_versions.length) {
                    qualification.previous_versions.forEach(function(prev) {
                        if(prev.complaints) {
                            prev.complaints.forEach(function(complaint) {
                                complaint.qualificationId = prev.id;
                                complaint.bidId = prev.bid_id;
                                complaint.relatesTo = 'qualification';
                                complaint.relatedItem = prev.id;
                                formationService.setSignToItem(complaint);
                                $scope.complaints.push(complaint);
                            });
                        }
                    });
                }
            });
        }

        if (tenderData.data.cancellations) {
            tenderData.data.cancellations.forEach(function(cancellation) {
                complaintService.getList($scope.tenderId, null, null, cancellation.id)
                    .then(function(data) {
                        console.log(data)
                        data.response.forEach(function(complaint) {
                            complaint.cancellationId = cancellation.id;
                            complaint.relatesTo = 'cancellation';
                            complaint.relatedItem = cancellation.id;
                            formationService.setSignToItem(complaint);
                            $scope.complaints.push(complaint);
                        });
                    });
            });
        }
    };

    $scope.refreshList();

    $scope.files = [];
    $scope.complaintId = null;
    $scope.awardId = null;
    $scope.qualificationId = null;
    $scope.cancellationId = null;

    $scope.publicAsClaim = function(complaintId, awardId, qualificationId, cancellationId) {
        complaintService.edit(tenderId, {status: 'claim'}, complaintId, awardId, qualificationId, cancellationId)
            .then(function(data) {
                $scope.refreshList();
            });
    };

    $scope.publicAsComplaint = function(complaintId, awardId, qualificationId, cancellationId) {

        return $uibModal.open({
            templateUrl: "/static/scripts/modules/TenderView/views/modals/tender-view.complaint.confirm.modal.html",
            controller: "tenderViewComplaintConfirmModalCtrl",
            resolve: {
                data: function() {
                    return {
                        awardId: awardId,
                        tenderId: tenderId,
                        complaintId: complaintId,
                        qualificationId: qualificationId,
                        cancellationId: cancellationId
                    };
                }
            },
            backdrop: 'static'
        }).result.then($scope.refreshList);
    };

    $scope.addObjections = function(complaint, relatedLot, awardId, qualificationId, cancellationId) {
        return $uibModal.open({
            templateUrl: "/static/scripts/modules/TenderView/views/modals/tender-view.complaint.objections.modal.html",
            controller: "tenderViewComplaintObjectionsCtrl",
            resolve: {
                data: function() {
                    return {
                        complaint,
                        tenderId: $scope.tenderId,
                        awardId: awardId,
                        qualificationId: qualificationId,
                        cancellationId: cancellationId,
                    };
                }
            },
            backdrop: 'static'
        }).result.then($scope.refreshList);
    };

    $scope.makeComplaint = function() {
        return $uibModal.open({
            templateUrl: "/static/scripts/modules/TenderView/views/modals/tender-view.complaint.modal.html",
            controller: "tenderViewComplaintModalCtrl",
            resolve: {
                data: function() {
                    return {
                        tenderId: $scope.tenderId,
                        isQuestion: true,
                        isMultilotTender: $scope.isMultilotTender,
                        lots: tenderData.data.lots,
                        isBelowThreshold: $scope.isPMT('belowThreshold')
                    };
                }
            },
            backdrop: 'static'
        }).result.then($scope.refreshList);
    };

    $scope.resolution = function (complaintId, awardId, qualificationId, cancellationId, isSatisfied) {
        // if ( isSatisfied && (qualificationId || awardId) &&
        //     !$scope.isPMT(['negotiation', 'negotiation.quick'])
        // ) {
        //     const key = qualificationId ? 'qualifications' : 'awards';
        //     const obj = $scope.tender[key].find(item => item.id === awardId || item.id === qualificationId);
        //
        //     // На пре-квалификации жалуются на то что отклонили предложение жалобщика. Устранинием
        //     // нарушений считается принятие предложения жалобщика.
        //     if (key === 'qualifications') {
        //         if (obj && obj.status === 'cancelled') {
        //             ntFlash.error(gettext('Не можна закривати cкаргу до усунення недоліків'));
        //             return;
        //         }
        //         // На квалификации жалуются на то что приняли предложение конкурента по-этому устранинием
        //         // нарушений считается отклонение award на который жаловались
        //     } else if (key === 'awards') {
        //         if (obj && obj.status !== 'cancelled') {
        //             ntFlash.error(gettext('Не можна закривати cкаргу до усунення недоліків'));
        //             return;
        //         }
        //     }
        // }

        return $uibModal.open({
            templateUrl: "/static/scripts/modules/TenderView/views/modals/tender-view.complaint.modal.html",
            controller: "tenderViewComplaintModalCtrl",
            resolve: {
                data: function() {
                    return {
                        tenderId: $scope.tenderId,
                        awardId: awardId,
                        qualificationId: qualificationId,
                        cancellationId: cancellationId,
                        complaintId: complaintId,
                        isQuestion: false,
                        isSatisfied: isSatisfied
                    };
                }
            },
            backdrop: 'static'
        }).result.then($scope.refreshList);
    };

    $scope.cancelComplaint = function(complaintId, awardId, qualificationId, cancellationId, type) {
        return $uibModal.open({
            templateUrl: "/static/scripts/modules/TenderView/views/modals/tender-view.complaint.modal.html",
            controller: "tenderViewComplaintModalCtrl",
            resolve: {
                data: function() {
                    return {
                        tenderId: $scope.tenderId,
                        awardId: awardId,
                        complaintId: complaintId,
                        qualificationId: qualificationId,
                        cancellationId: cancellationId,
                        isQuestion: false,
                        isCancellation: true,
                        complaintType: type,
                        isBelowThreshold: $scope.isPMT('belowThreshold')
                    };
                }
            },
            backdrop: 'static'
        }).result.then($scope.refreshList);
    };

    $scope.getDocument = (complaint, relatedDocument) => {

        const document = complaint.documents.find((doc) =>{

            return doc.id === relatedDocument
        })

        return document.title

    }

    $scope.resolve = function (claimSatisfied, complaintId, awardId, qualificationId, cancellationId) {
        let reqData = {
            satisfied: claimSatisfied
        };
        if (claimSatisfied) reqData.status = 'resolved';
        complaintService.edit($scope.tenderId, reqData, complaintId, awardId, qualificationId, cancellationId)
            .then(function(data) {
                $scope.refreshList();
            });
    };

    $scope.$watch('files', function() {
        if ($scope.files && $scope.files.length) {
            var request = documentsService.save($scope.files[0], $scope.tenderId, {
                complaintId: $scope.complaintId,
                awardId: $scope.awardId,
                qualificationId: $scope.qualificationId,
                cancellationId: $scope.cancellationId
            });
            request
                .then(function() {
                    ntFlash.success(gettext('Документ успешно загружен.'));
                    $scope.refreshList();
                });
        }
    });

    $scope.setComplaintId = function(complaintId, awardId, qualificationId, cancellationId) {
        $scope.qualificationId = qualificationId;
        $scope.cancellationId = cancellationId;
        $scope.complaintId = complaintId;
        $scope.awardId = awardId;
    };

    $scope.sign = function(complaint) {

        const url = complaintService.getLinkForSign( $scope.tenderId, complaint.id, complaint.awardId, complaint.qualificationId, complaint.cancellationId)
        const docUrl = complaintService.getLinkForSignDoc( $scope.tenderId, complaint.id, complaint.awardId, complaint.qualificationId, complaint.cancellationId)
        console.log(url)
        const data = {
            // complaintId: complaint.id,
            complaintURL: url,
            complaintDOCURL: docUrl,
            // tenderId: $scope.tenderId,
            documents: complaint.documents || (complaint.documents = [])
        };
        documentsService.sign(data)
            .then(data => console.log('documentsService.sign data', data))
            .catch(error => console.log('documentsService.sign error', error));
    };
});
