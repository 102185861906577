tenderView.controller('tenderViewComplaintObjectionsCtrl', function(
    $scope,
    $timeout,
    $state,
    ntFlash,
    $rootScope,
    data,
    complaintService,
    documentsService,
    $uibModalInstance,
    loadExternalDataService,
) {

        $scope.complain = data.complaint
        $scope.complain_documents = data.complaint.documents || []

        $scope.tender = data.tender
        $scope.canRelates = data.canRelates
        $scope.relatesTo = data.relatesTo
        this.ExternalDataService =   loadExternalDataService;
    $scope.Objections = []
    $scope.classifiers = []
    $scope.remedies_type = []
    console.log(data)


    this.getClassifier = () => {
        this.ExternalDataService.classifierObjections16()
            .then((data) => {
                data.forEach((el) => {
                    const classification = {
                        scheme: 'article_16',
                        id: el.classification.id,
                        description: el.title
                    }
                    $scope.classifiers.push(classification)
                })
            })
        this.ExternalDataService.getCriterias()
            .then((data) => {
                data.forEach((el) => {
                    const classification = {
                        scheme: 'article_17',
                        id: el.classification.id,
                        description: el.title
                    }
                    $scope.classifiers.push(classification)
                })
            })
        this.ExternalDataService.getOtherCriterias()
            .then((data) => {
                data.forEach((el) => {
                    const classification = {
                        scheme: 'other',
                        id: el.classification.id,
                        description: el.title
                    }
                    $scope.classifiers.push(classification)
                })
            })
        this.ExternalDataService.violation_amcu()
            .then((data) => {
                for (const [key, value] of Object.entries(data)) {
                    const classification = {
                        scheme: 'violation_amcu',
                        id: key,
                        description: value.title_uk
                    }
                    $scope.classifiers.push(classification)
                }
            })
        this.ExternalDataService.requested_remedies_type()
            .then((data) => {
                for (const [key, value] of Object.entries(data)) {
                    const classification = {
                        type: key,
                        description: value.title_uk
                    }
                    $scope.remedies_type.push(classification)

                }
            })
    }
    this.getClassifier()

    $scope.addObjections = () => {

        if($scope.complain.objections){
            $scope.Objections = $scope.complain.objections
        } else {
            const EmptyObjections = {
                title: "",
                description: "",
                relatesTo: $scope.complain.relatesTo,
                relatedItem: $scope.complain.relatedItem,
                classification: {
                    scheme: '',
                    id: '',
                    description: '',
                },
                requestedRemedies: [{
                    type: "",
                    description: "",
                    tempData: ''
                }],
                arguments: [{
                    description: '',
                    evidences: []
                }]
            }
            $scope.Objections.push(EmptyObjections)
        }


    }
    $scope.addObjections()

    $scope.removeObjections = (index) => {
        $scope.Objections.splice(index, 1)
    }

    $scope.addArguments = function($index) {
        const argument = {
            description: '',
            evidences: []
        }
        $scope.Objections[$index].arguments.push(argument)
    }

    $scope.removeArguments = ($index, $index1) => {
        $scope.Objections[$index].arguments.splice($index1, 1)
    }

    $scope.addEvidences = function($index, $index2) {
        const evidence = {
            title: "",
            description: "",
            relatedDocument: ""
        }
        $scope.Objections[$index].arguments[$index2].evidences.push(evidence)
    }

    $scope.removeEvidence = function($index, $index2, $index3) {

        $scope.Objections[$index].arguments[$index2].evidences.splice($index3, 1)
    }

    $scope.addRequestedRemedies = function($index) {
        const RequestedRemedies = {
            type: "",
            description: "",
            tempData: ''
        }
        $scope.Objections[$index].requestedRemedies.push(RequestedRemedies)
    }

    $scope.removeRequestedRemedies = function($index, $index2) {
        $scope.Objections[$index].requestedRemedies.splice($index2, 1)
    }

    $scope.setDescription = ($index, $index2) => {

        $scope.Objections[$index].requestedRemedies[$index2] = {
            ...$scope.Objections[$index].requestedRemedies[$index2],
            type: $scope.Objections[$index].requestedRemedies[$index2].tempData.type,
            description: $scope.Objections[$index].requestedRemedies[$index2].tempData.description
        }


    }

    $scope.save = () =>{
        const current = {
            objections: $scope.Objections.map((objection) => {
                objection.requestedRemedies.map((Remedie) =>{
                    delete Remedie.tempData
                    return Remedie
                })
                return objection
            })
        }

        complaintService.edit(data.tenderId, current, $scope.complain.id, data.awardId, data.qualificationId, data.cancellationId)
            .then(function(data) {
                console.log('complaintService.create data', data);
                $timeout(() => {
                    $state.go('tenderView.complaint', {}, {
                        reload: true
                    });
                    $uibModalInstance.close();
                }, 500);
            })
            .catch(function(error) {
                console.log('complaintService.create error', error);
                ntFlash.error(gettext('Сталася помилка'));
                $uibModalInstance.close();
            });
    }

    $scope.close = function () {

        $uibModalInstance.close();
    };
    }
)
