tenderView.controller('tenderViewCtrl', function (
    $scope,
    $stateParams,
    $state,
    tenderData,
    $rootScope,
    tenderService,
    bookmarksService,
    $uibModal,
    ntFlash,
    $filter,
    formationService,
    $timeout,
    $mdDialog,
    tenderPeriodsListService,
    agreementInstanceService,
    exchangeService,
    seoService,
    documentsService,
    $window
) {
    $scope.isCZO = $window.OPC_CONFIG.PROCURING_ENTITY.kind === 'central';
    $scope.currentState = $state.current.name;
    $scope.agreemnt = null

    var tenderTitle = gettext('Закупка') + ' - ' + seoService.cutString(tenderData.data.title, 80) + ' - Newtend';
    seoService.setTitle(tenderTitle);

    var seoAmount = ' ',
        seoStartDate = ' ',
        tenderDescription = '';

    if (tenderData.data.value && tenderData.data.value.amount && tenderData.data.value.currency) {
        seoAmount = ' ' + gettext('Бюджет') + ' - ' + tenderData.data.value.amount + ' ' + gettext(tenderData.data.value.currency);
    }
    if (tenderData.data.tenderPeriod && tenderData.data.tenderPeriod.startDate) {
        seoStartDate = ' ' + gettext('от') + ' ' + $filter('date')(tenderData.data.tenderPeriod.startDate, 'dd/MM/yyyy');
    }
    tenderDescription = gettext('Закупка, тендер') + ' - ' + seoService.cutString(tenderData.data.title, 100) + seoStartDate + seoAmount + ' - ' + gettext('Ньютенд');

    seoService.setMetaDescription(tenderDescription);

    $scope.tenderPeriods = tenderPeriodsListService.getList();
    // костыль, переделать через tenderService
    if ( tenderData.data.procurementMethodType == 'closeFrameworkAgreementSelectionUA' ) {
        $scope.tenderPeriods[0].label = gettext('Період запрошення');
    }



    $scope.isBidProposal = tenderService.isCompetitiveDialogue(tenderData.data);
    $scope.isAboveProcurement = tenderService.isAbove(tenderData.data);
    $scope.isBelowProcurement = tenderService.isBelow(tenderData.data);
    $scope.isCompetitiveDialogue = tenderService.isCompetitiveDialogue(tenderData.data) ||
                                   tenderService.isCompetitiveDialogue2Stage(tenderData.data);
    $scope.isCompetitiveDialogueStage2 = tenderService.isCompetitiveDialogue2Stage(tenderData.data);
    var alowedBidTypes = OPC_CONFIG.OPC_BID_PROCUREMENT_METHOD_TYPE,
        bidAmount;

    exchangeService.getExchangeRate()
        .then(function (result) {
            $scope.exchangeRates = result.response;
        });

    $scope.isMultilotTender = tenderData.data.lots && tenderData.data.lots.length ? true : false;

    $scope.tender = tenderData.data;
    $scope.actions = tenderData.action;
    $scope.currentBid = tenderData.bid;
    $scope.bookmark = tenderData.bookmark;
    $scope.tenderId = tenderData.data.cdb_id;
    $scope.tenderID = tenderData.data.tenderID;
    $scope.tenderCdbId = $stateParams.id;
    $scope.isBlockedTender = tenderService.isBlockedTender(tenderData.data);
    $scope.isCancelledTender = tenderData.data.status == 'cancelled';
    $scope.config = tenderData.config
    $scope.currentTime = new Date();
    $scope.isSigned = null;
    $scope.dateSigned = null;

    $scope.isPendingCancellation = false;
    $scope.isPendingLotCancellation = false;
    $scope.pendingTenderCancellation = null;
    $scope.pendingLotCancellation = [];
    $scope.cancelStatus = '';
    $scope.LotCancelStatus = '';
    ($scope.tender.cancellations || []).forEach(item => {
        if (item.cancellationOf === 'tender' && item.status === 'pending') {
            $scope.pendingTenderCancellation = item;
            $scope.isPendingTenderCancellation = true;
        }
        if (item.cancellationOf === 'lot' && item.status === 'pending') {
            $scope.pendingLotCancellation.push(item);
            $scope.isPendingLotCancellation = true
        }
        if (item.cancellationOf === 'tender' && item.status === 'active') $scope.cancelStatus = item.reasonType;
        if (item.cancellationOf === 'lot' && item.status === 'active') $scope.LotcancelStatus = item.reasonType;
    });

    $scope.hasPendingTenderCancellationSatisfiedComplaint = false;
    if ($scope.pendingTenderCancellation && $scope.pendingTenderCancellation.complaints) {
        $scope.hasPendingTenderCancellationSatisfiedComplaint = $scope.pendingTenderCancellation.complaints.some(el => el.status === 'satisfied');
    }

    if ($scope.tender.contracts && $scope.tender.contracts.length) {
        $scope.contractId = $scope.tender.contracts[0].id;
    }

    $scope.unansweredComplain = tenderService.hasUnansweredComplains(tenderData.data);

    $scope.bidPlaced = false;
    $scope.isBidDraft = false;
    $scope.bidInvalid = false;
    $scope.currency = '';

    if ($scope.currentBid) {
        $scope.bidPlaced = true;
        $scope.isBidDraft = $scope.currentBid.status === "local_draft" || $scope.currentBid.status === "draft";
        $scope.bidInvalid = $scope.currentBid.status === "invalid";
        if ($scope.currentBid.value) {
            bidAmount = $scope.currentBid.value.amount;
            $scope.currency = $scope.currentBid.value.currency;
            $scope.valueAddedTaxIncluded = $scope.currentBid.value.valueAddedTaxIncluded
        } else if (!$scope.isBidProposal) {
            bidAmount = 0;
            if ($scope.currentBid.lotValues && $scope.currentBid.lotValues.length) {
                $scope.currency = $scope.currentBid.lotValues[0].value.currency;
                $scope.currentBid.lotValues.forEach(function (lot) {
                    bidAmount += lot.value.amount;
                });
                $scope.valueAddedTaxIncluded = $scope.currentBid.lotValues[0].value.valueAddedTaxIncluded
            }
        }

        $scope.lastBidAmount = (bidAmount && bidAmount.toFixed(2));
        $scope.lastBidParameters = $scope.currentBid.parameters;
        $scope.participationUrl = $scope.currentBid.participationUrl || ' ';
        $rootScope.participationUrl = $scope.participationUrl;
        $scope.bidDate = $scope.currentBid.date;
        formationService.setSignToItem($scope.currentBid);
        $rootScope.$emit('canUploadDocs', true);
    } else {
        bidAmount = null;
        $rootScope.$emit('canUploadDocs', false);
    }
    if ( tenderData.data.procurementMethodType == 'competitiveOrdering' ) {

        agreementInstanceService.getAgreements(tenderData.data.agreements[0].id).then((res) => {

            $scope.agreemnt = res.response
        });
    }

    $scope.getLotNameById = function(id){
        return $scope.tender.lots.find((item)=> {return item.id === id})
    }

    $scope.placeBid = function () {
        if (alowedBidTypes[$scope.tender.procurementMethodType]) {
            return $uibModal.open({
                templateUrl: '/static/scripts/modules/TenderView/views/modals/bid-modal.html',
                controller: 'bidModalCtrl',
                windowClass: 'center-modal',
                backdrop: 'static',
                resolve: {
                    data: function () {
                        return {
                            config: $scope.config,
                            isCancel: false,
                            currentTender: tenderData,
                            currentBid: $scope.currentBid,
                            bidAmount: bidAmount,
                        };
                    }
                }
            }).result.then((bid) => {
                console.log('placeBid modal closed data', bid);
                $timeout(() => {
                    $state.go('tenderView.ownBid', {}, { reload: true });
                }, 500);
                ntFlash.info(gettext('Ваша Пропозиція / Ставка створена. Завантажте документи'));
            });
        } else {
            $scope.showAlert();
        }
    };

    $scope.cancelBid = function () {
        return $uibModal.open({
            templateUrl: '/static/scripts/modules/TenderView/views/modals/bid-modal.html',
            controller: 'bidModalCtrl',
            windowClass: 'center-modal',
            resolve: {
                data: function () {
                    return {
                        isCancel: true,
                        currentBid: $scope.currentBid,
                        currentTender: tenderData
                    };
                }
            }
        }).result.then(function () {
            console.log('cancelBid modal closed');
            $timeout(() => {
                $state.reload();
            }, 500);
            ntFlash.info(gettext('Ваша Пропозиція / Ставка відмінена'));
        });
    };

    $scope.canBid = () => {
        let canBid = false
        if($scope.agreemnt && $window.OPC_CONFIG.PROCURING_ENTITY.identifier && $scope.agreemnt.status === 'active'){
            const user_edrpou = $window.OPC_CONFIG.PROCURING_ENTITY.identifier.id
            $scope.agreemnt.contracts.forEach((contract) => {
                if(contract.suppliers[0].identifier.id === user_edrpou && contract.status == "active"){
                    canBid = true
                }
            })
        }
        return canBid
    }

    $scope.selectedFeatures = function (parameters) {
        var title = "";
        var lineNew = " \n";
        if (parameters && parameters.length > 0) {
            parameters.forEach(function (parameter) {
                $scope.tender.features.forEach(function (feature) {
                    if (feature.code === parameter.code) {
                        feature.enum.forEach(function (option) {
                            if (option.value == parameter.value) {
                                title += feature.title + ": " + option.title + " - " + option.value + lineNew;
                            }
                        });
                    }
                });
            });
        }
        return title;
    };

    $scope.showAlert = function () {
        ntFlash.info("Уважаемые пользователи! Электронная площадка NEWTEND находится в режиме " +
            "тестирования в соответствии постановлением КМУ №166 от 24.02.2016 года. Сразу после " +
            "принятия Комиссией решения об авторизации, которое ожидается в ближайшее время, " +
            "вы сможете организовывать или участвовать в процедурах закупок, предусмотренных " +
            "Законом Украины «О публичных закупках» через  нашу площадку. Для заказчиков, " +
            "которые  до 01.04.2016 года создали  допороговую закупку  на площадке NEWTEND " +
            "и для поставщиков, которые подали предложения на такие тендеры, функционал нашей " +
            "площадки работает без ограничений. Как только будет принято решение об авторизации " +
            "и подключении электронной площадки NEWTEND к электронной системе закупок, " +
            "мы обязательно дополнительно уведомим вас.");
    };

    const signBidSplitedValue = [
        'aboveThresholdEU',
        'competitiveDialogueUA',
        'competitiveDialogueEU',
        'competitiveDialogueEU.stage2',
        'esco',
        'closeFrameworkAgreementUA',
    ];

    $scope.signBid = function (bid) {
        if ($rootScope.oldEds) {
            return $uibModal.open({
                templateUrl: '/static/scripts/modules/Commons/views/e-sign-modal.html',
                controller: 'eSignModalCtrl',
                resolve: {
                    data: function () {
                        return {
                            bid: bid,
                            tenderId: $scope.tenderId,
                            documents: bid.documents || (bid.documents = []),
                            financialDocuments: signBidSplitedValue.includes($scope.tender.procurementMethodType) ?
                                bid.financialDocuments || (bid.financialDocuments = []) : null,
                        };
                    }
                },
                windowClass: 'center-modal'
            });
        } else {
            const data = {
                bid: bid,
                tenderId: $scope.tenderId,
                documents: bid.documents || (bid.documents = []),
                financialDocuments: signBidSplitedValue.includes($scope.tender.procurementMethodType)
                    ? bid.financialDocuments || (bid.financialDocuments = [])
                    : null    
            };
            documentsService.sign(data, {
                mustReload: true,
                checkCompany: true
            })
                .then(data => console.log('documentsService.sign data', data))
                .catch(error => console.log('documentsService.sign error', error));
        }
    };

    if ($scope.tender.documents && $scope.tender.documents.length) {
        $scope.tender.documents = $filter('versionFilter')($scope.tender.documents);
        $scope.tender.documents.forEach((document) => {
            if (document.title === 'sign.p7s' && document.format === 'application/pkcs7-signature') {
                $scope.isSigned = true;
                $scope.dateSigned = document.dateModified;
            }
        });
    }

    $state.get('tenderView').onExit = function () {
        $rootScope.participationUrl = null;
    };

    $rootScope.$on('canUploadDocs', function (e, flag) {
        if ($scope.availableActions) {
            $scope.availableActions[2].actions = flag && !$scope.actions.tender_closed;
        }
    });

    var avaialableActions = {
        customer: [{
                name: gettext('Редактировать тендер'),
                actions: $scope.actions.can_edit_tender && !$scope.actions.tender_closed
            },
            {
                name: gettext('Отвечать на вопросы'),
                actions: $scope.actions.can_replay_questions && !$scope.actions.tender_closed
            },
            {
                name: gettext('Отменять тендер'),
                actions: $scope.actions.can_cancel_tender && !$scope.actions.tender_closed
            },
            {
                name: gettext('Добавлять документы'),
                actions: $scope.actions.can_upload_tender_docs && !$scope.actions.tender_closed
            },
            {
                name: gettext('Выбрать победителя'),
                actions: $scope.actions.can_select_winner && !$scope.actions.tender_closed
            },
            {
                name: gettext('Загрузить документы для подписания договора'),
                actions: $scope.actions.can_upload_contract_docs && !$scope.actions.tender_closed
            },
            {
                name: gettext('Отменить подписание договора'),
                actions: $scope.actions.can_reject_contract && !$scope.actions.tender_closed
            },
            {
                name: gettext('Подписать договор'),
                actions: $scope.actions.can_signing_contract && !$scope.actions.tender_closed
            },
            {
                name: gettext('Пригласть поставщиков'),
                actions: $scope.actions.can_invite && !$scope.actions.tender_closed
            }
        ],
        provider: [{
                name: gettext('Задавать вопросы'),
                actions: $scope.actions.can_ask_questions && !$scope.actions.tender_closed
            },
            {
                name: gettext('Сделать/Изменить/Удалить ставку'),
                actions: $scope.actions.can_create_bids && !$scope.actions.tender_closed
            },
            {
                name: gettext('Добавить к предложению документы'),
                actions: $scope.actions.can_upload_bid_docs && !$scope.actions.tender_closed
            },
            {
                name: gettext('Участвовать в аукционе'),
                actions: $rootScope.participationUrl && $rootScope.participationUrl != " " && !$scope.actions.tender_closed && $scope.tender.status == 'active.auction'
            }
        ]
    };

    setTimeout(function () {
        $scope.availableActions = !$scope.actions.tender_owner ? avaialableActions.provider : avaialableActions.customer;
    }, 1000);

    $scope.isDateInPeriod = (date, from, to) => {
        return to ? date > (new Date(from)) && date < (new Date(to)) : date > (new Date(from));
    };

    $scope.isDatePassed = (date, to) => {
        return to && date > (new Date(to));
    };

    $scope.sign = function () {
        if ($rootScope.oldEds) {
            return $uibModal.open({
                templateUrl: '/static/scripts/modules/Commons/views/e-sign-modal.html',
                controller: 'eSignModalCtrl',
                resolve: {
                    data: function () {
                        return {
                            tenderId: $scope.tender.id,
                            documents: $scope.tender.documents || ($scope.tender.documents = [])
                        };
                    }
                },
                windowClass: 'center-modal'
            });
        } else {
            const data = {
                tenderId: $scope.tender.id,
                documents: $scope.tender.documents || ($scope.tender.documents = [])    
            };
            documentsService.sign(data)
                .then(data => console.log('documentsService.sign data', data))
                .catch(error => console.log('documentsService.sign error', error));
        }
    };

    $scope.makeAlert = function () {
        if ($scope.tender.procurementMethodType === "aboveThresholdEU" ||
            $scope.tender.procurementMethodType === "aboveThresholdUA" ||
            $scope.tender.procurementMethodType === "aboveThresholdUA.defense" ||
            $scope.tender.procurementMethodType === "simple.defense"
        ) {
            var now = new Date(),
                DAY = 86400000,
                WEEK = DAY * 7,
                targetDate = new Date(+now + WEEK),
                tenderPeriodEndDate = new Date($scope.tender.tenderPeriod.endDate);

            if (tenderPeriodEndDate < targetDate) {
                ntFlash.info(gettext("Внесення змін до цього оголошення потребуватиме продовження терміну прийому пропозицій до ") + $filter('date')(targetDate, 'dd.MM.yyyy HH:mm'));
            }
        }
    };

    $scope.startSecondStageTender = function () {
        return $uibModal.open({
            templateUrl: '/static/scripts/modules/TenderView/views/modals/tender-start-stage2-modal.html',
            controller: 'tenderStartStage2ModalCtrl',
            resolve: {
                data: function () {
                    return {
                        tender: $scope.tender
                    };
                }
            },
            windowClass: 'center-modal',
            backdrop: 'static'
        });
    };

    $scope.activateTender = (tender) => {
        tenderService.patch(tender.id, {
            tenderPeriod: {
                endDate: tender.tenderPeriod.endDate
            },
            status: "active.tendering"
        }).then((result) => {
            $window.location.reload();
        });
    };

    $scope.deleteTender = function (lotId) {
        return $uibModal.open({
            templateUrl: '/static/scripts/modules/TenderView/views/modals/tender-delete-modal.html',
            controller: 'tenderDeleteModalCtrl',
            resolve: {
                data: function () {
                    return {
                        tenderId: $scope.tender.cdb_id,
                        lotId: lotId,
                        procurementMethodType: $scope.tender.procurementMethodType,
                        kind: $scope.tender.procuringEntity.kind,
                        amount: ($scope.tender.value && $scope.tender.value.amount) ? $scope.tender.value.amount : null
                    };
                }
            },
            windowClass: 'center-modal',
            backdrop: 'static'
        });
    };

    $scope.recoverTender = function (cancellationId) {
        tenderService.recoverTenderCancellation({
            tenderId: $scope.tender.id,
            cancellationId: cancellationId
        })
            .then(data => {
                console.log('tenderService.recoverTenderCancellation data', data);
                $timeout(function() {
                    $state.reload();
                }, 1000);
                ntFlash.success(gettext('Тендер/Лот успешно восстановлен.'));
            })
            .catch(error => {
                console.log('tenderService.recoverTenderCancellation error', error);
                ntFlash.error(gettext('Сталася помилка ', error));
            });
    }

    $scope.startSecondStageTender = function () {
        return $uibModal.open({
            templateUrl: '/static/scripts/modules/TenderView/views/modals/tender-start-stage2-modal.html',
            controller: 'tenderStartStage2ModalCtrl',
            resolve: {
                data: function () {
                    return {
                        tender: $scope.tender
                    };
                }
            },
            windowClass: 'center-modal',
            backdrop: 'static'
        });
    };

    $scope.goTo2Stage = () => {
        tenderService
            .getTender2Stage($scope.tender.id)
            .then(resp => {
                $state.go('tenderView', {
                    id: resp.data.response.data.id
                }, {
                    reload: true
                });
            });
    };

    $scope.showPublicateBidConfirm = () => {
        $mdDialog.show({
            templateUrl: '/static/scripts/modules/TenderView/views/modals/bid.publish-draft.html',
            controller: 'bidPublishDraft',
            controllerAs: 'vm',
            resolve: {
                data: () => {
                    return {
                        tender: $scope.tender,
                        bid: $scope.currentBid
                    };
                }
            }
        });
    };

    $scope.addPlanToTenderCZO = function() {
        return $uibModal.open({
            templateUrl: '/static/scripts/modules/TenderView/views/modals/tender-view.czo.add-plan.html',
            controller: 'tenderViewCzoAddPlanCtrl',
            resolve: {
                data: function () {
                    return {
                        tenderId: $scope.tender.id
                    };
                }
            },
            windowClass: 'center-modal',
            backdrop: 'static'
        });
    };

    $scope.publishTender = function(tender) {
        let status = '';
        const methodsActiveTendering = [
            'aboveThresholdUA.defense',
            'simple.defense',
            'aboveThresholdUA',
            'aboveThresholdEU',
            'competitiveDialogueUA',
            'CompetitiveDialogueEU',
            'esco',
            'closeFrameworkAgreementUA'
        ];
        const methodsActive = [
            'reporting',
            'negotiation',
            'negotiation.quick'
        ];
        if (methodsActiveTendering.includes(tender.procurementMethodType)) {
            status = 'active.tendering';
        } else if (methodsActive.includes(tender.procurementMethodType)) {
            status = 'active';
        } else {
            status = 'active.enquiries';
        }
        tenderService.patch(tender.id, { status: status })
            .then(result => {
                console.log('tenderService patch result', result);
                ntFlash.success(gettext('Закупівля переведена в активний статус'));
                $window.location.reload();
            })
            .catch(error => {
                console.log('tenderService patch error', error);
                ntFlash.error(gettext('Під час переведення Закупівлі в активний статус виникла помилка'));
            });
    };

    $scope.makeComplaint = function(cancellationId) {
        return $uibModal.open({
            templateUrl: "/static/scripts/modules/TenderView/views/modals/tender-view.complaint.modal.html",
            controller: "tenderViewComplaintModalCtrl",
            resolve: {
                data: function() {
                    return {
                        tenderId: $scope.tender.id,
                        cancellationId: cancellationId,
                        isQuestion: true,
                        isMultilotTender: false,
                        isBelowThreshold: $scope.tender.procurementMethodType === 'belowThreshold'
                    }
                }
            },
            backdrop: 'static'
        })
    };

    $scope.addBookmark = function($event, tenderId) {
        console.log('tenderId', tenderId);
        
        $event.stopPropagation();
        $event.preventDefault();
        bookmarksService.save(tenderId)
            .then(()=>{
                $state.reload();
            });
    };

    $scope.deleteBookmark = function($event, tenderId) {
        $event.stopPropagation();
        $event.preventDefault();
        bookmarksService.delete(tenderId)
            .then(()=>{
                $state.reload();
            });
    };
});
