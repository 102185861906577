class TenderMilestones {
    constructor(uuid4, md5) {
        this.uuid4 = uuid4;
        this.md5 = md5;
    }

    $onInit() {
        this.milestones = this.milestones || [];
        this.lot = this.lot || null;
    }

    getMilestones() {
        let result = [];
        for (let i=0, size=this.milestones.length; i < size; i++) {
            if ((!this.lot && !this.milestones[i].relatedLot) || (this.lot && this.lot.id === this.milestones[i].relatedLot)) {
                result.push(this.milestones[i]);
            }
        }
        return result;
    }

    addMilestone() {
        let maxSequenceNumber = this.milestones.length?this.milestones.reduce((max, p) => p.sequenceNumber > max ? p.sequenceNumber : max, this.milestones[0].sequenceNumber)+1:0;

        let milestone = {
            id: this.md5.createHash(this.uuid4.generate()),
            title: '',
            type: 'financing',
            description: '',
            code: '',
            // dueDate: '',
            // dateMet: '',
            // dateModified: '',
            // status: '',
            // value: '',
            relatedLot: this.lot ? this.lot.id : undefined,
            percentage: undefined,
            duration: {
                days: undefined,
                type: '',
            },
            sequenceNumber: maxSequenceNumber
        };
        this.milestones.push(milestone);
    }

    delMilestone(milestone) {
        // console.log('Delete: ', milestone);
        let index = this.milestones.map((i) => { return i.id; }).indexOf(milestone.id);
        if (index > -1) {
            this.milestones.splice(index, 1);
        }
        this.milestones.forEach((milestone,i) => {
            milestone.sequenceNumber = i;
        });
    }

}

tenderEdit.component('tenderMilestones', {
    templateUrl: '/static/scripts/modules/TenderEdit/views/components/tender-milestones.html',
    controller: TenderMilestones,
    controllerAs: 'vm',
    bindings: {
        milestones: '<',
        lot: '<',
        procurementMethodType: '<',
        ngDisabled: '<',
    }
});