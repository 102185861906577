tenderView.controller('tenderViewOwnBidCtrl', function(
    $scope,
    $state,
    $filter,
    tenderData,
    tenderBidService,
    billingService,
    documentsService,
    tenderService,
    $timeout,
    $interval,
    ntFlash,
    $uibModal,
    md5,
    uuid4
) {
    console.log('tenderViewOwnBidCtrl tenderData', tenderData);
    $scope.loadInProgress = false;
    $scope.selected = {
        files: []
    };
    $scope.replace = {
        files: []
    };
    $scope.addedFiles = [];
    $scope.deletedFiles = [];
    $scope.loadDocOption = false
    $scope.loadDocOptionList = {
        documentType: 'technicalSpecifications',
        documentOf: 'tender',
        relatedItem: '',
        confidentiality: 'public',
        confidentialityRationale: ''
    }

    $scope.tender = tenderData.data;
    $scope.actions = tenderData.action;
    $scope.tenderId = $scope.tender.id;
    $scope.tenderID = $scope.tender.tenderID;
    $scope.currentBid = tenderData.bid;
    $scope.isMultilotTender = $scope.tender.lots && $scope.tender.lots.length ? true : false;

    $scope.fullForm = false;

    $scope.isMilestones24h = false;
    $scope.isMilestonesAlp = false;
    if ($scope.currentBid && $scope.tender.status === 'active.pre-qualification') {
        ($scope.tender.qualifications || []).forEach(qualification => {
            if ($scope.currentBid.id === qualification.bidID) {
                (qualification.milestones || []).forEach(milestone => {
                    if (milestone.code === '24h' && +new Date(milestone.dueDate) > +new Date())
                        $scope.isMilestones24h = true;
                });
            }
        });
    } else if ($scope.currentBid && $scope.tender.status === 'active.qualification') {
        ($scope.tender.awards || []).forEach(award => {
            if ($scope.currentBid.id === award.bid_id) {
                (award.milestones || []).forEach(milestone => {
                    if (+new Date(milestone.dueDate) > +new Date()) {
                        if (milestone.code === '24h') $scope.isMilestones24h = true;
                        else if (milestone.code === 'alp') $scope.isMilestonesAlp = true;
                    }
                });
            }
        });
    }

    $scope.documentTypeAndConfidentialityScheme = tenderService.setDocumentTypeAndConfidentialityScheme(
        $scope.tender.status,
        $scope.tender.procurementMethodType,
        false, false);

    $scope.titlesDictionary = {};
    ($scope.tender.lots || []).forEach(function(lot) {
        $scope.titlesDictionary[lot.id] = lot.title;
    });
    ($scope.tender.items || []).forEach(function(item) {
        $scope.titlesDictionary[item.id] = item.description;
    });
    
    if ($scope.currentBid && $scope.currentBid.parameters) {
        $scope.currentBid.parameters.forEach(function(parameter) {
            const featureItem = ($scope.tender.features || []).find(feature => feature.code === parameter.code);
            if (featureItem) {
                parameter.featureTitle = featureItem.title || '';
                const enumItem = (featureItem.enum || []).find(e => e.value === parameter.value);
                parameter.enumTitle = enumItem.title || '';
            }
        });
    }

    if ($scope.tender.lots && $scope.currentBid && $scope.currentBid.lotValues) {
        $scope.tender.lots.forEach(elem => {
            elem.disabled = true;
            for (const item of $scope.currentBid.lotValues) {
                if (elem.id === item.relatedLot) elem.disabled = false;
            }
        });
    }

    if ($scope.tender.criteria && $scope.currentBid) {
        $scope.tempCriteria = []
         $scope.tender.criteria.forEach((el)=>{
            if(el.relatesTo == 'lot'){
                    $scope.currentBid.lotValues.forEach(elem => {
                       if(elem.relatedLot === el.relatedItem){
                           console.log(elem.relatedLot === el.relatedItem)
                           $scope.tempCriteria.push(el)
                       }
                    });
            } else {
                $scope.tempCriteria.push(el);
            }
        })


        $scope.criterias = $scope.tempCriteria.filter(criteria => criteria.source !== 'procuringEntity');
        $scope.criterias.forEach(criteria => {
            criteria.requirementGroups.forEach((group, index) => {

                let requirementResponse = (criteria.classification.id === 'CRITERION.OTHER.BID.LANGUAGE') ? true : false

                if (index === 0) criteria.requirementGroupsSelected = group.id;
                group.requirements.forEach(requirement => {
                    if (criteria.source === 'winner') delete requirement.eligibleEvidences;
                    let response;
                    if (!requirement.status || requirement.status && requirement.status !== 'cancelled') {
                        if ($scope.currentBid.requirementResponses) {
                            response = $scope.currentBid.requirementResponses.find(item => item.requirement && item.requirement.id === requirement.id);
                            if (response) {
                                if (typeof response.value === 'string') { // boolean response value can have string type - 'True' or 'False'
                                    if (response.value.toLowerCase() === 'true') response.value = true;
                                    else if (response.value.toLowerCase() === 'false') response.value = false;
                                }
                                requirement.requirementResponse = {
                                    id: response.id,
                                    requirement: response.requirement,
                                    value: response.value,
                                    evidences: []
                                };
                                (requirement.eligibleEvidences || []).forEach((evidence, index) => {
                                    if (Array.isArray(response.evidences) && index < response.evidences.length)
                                        requirement.requirementResponse.evidences.push(response.evidences[index]);
                                    else
                                        requirement.requirementResponse.evidences.push(createEvidence(evidence));
                                });
                                criteria.requirementGroupsSelected = group.id;
                            }
                        }
                        if (!$scope.currentBid.requirementResponses || !response) {
                            requirement.requirementResponse = {
                                id: md5.createHash(uuid4.generate()),
                                requirement: {
                                    id: requirement.id,
                                    title: requirement.title
                                },
                                value: requirement.dataType === 'boolean' ? requirementResponse : '',
                                evidences: []
                            };


                            (requirement.eligibleEvidences || []).forEach(evidence => {
                                requirement.requirementResponse.evidences.push(createEvidence(evidence));
                            });

                        }
                    }
                });
            });
        });
    }

    function createEvidence(evidence) {
        // console.log(evidence)
        if(evidence.type === 'statement'){
            return {
                id: md5.createHash(uuid4.generate()),
                type: evidence.type,
                description: '',
                // relatedDocument: { id: '' }
            };
        } else {
            return {
                id: md5.createHash(uuid4.generate()),
                type: evidence.type,
                description: '',
                relatedDocument: { id: '' }
            };
        }

    }

    function initBidData(data = $scope) {
        $scope.isBidDraft = $scope.currentBid && ($scope.currentBid.status === "local_draft" || $scope.currentBid.status === "draft");
        $scope.bidInvalid = $scope.currentBid && ['invalid'].includes($scope.currentBid.status);

        $scope.allBidDocuments = [];
        $scope.eligibilityDocuments = [];

        if ($scope.currentBid) {
            prepareBidDoc()

            if ($scope.currentBid.meta) { //
                $scope.publishStatus = $scope.currentBid.meta.publishStatus; // 'failed', 'pending'
                $scope.upload_progress = $scope.currentBid.meta.uploadProgres //
                    ? +($scope.currentBid.meta.uploadProgres[0] / $scope.currentBid.meta.uploadProgres[1] * 100).toFixed(1) //
                    : 0;
            } else $scope.publishStatus = $scope.upload_progress = undefined; //

            $scope.eligibilityDocuments.forEach(function(item) {
                $scope.titlesDictionary[item.id] = item.title;
            });
        }
    }


    function prepareBidDoc(){
        if ($scope.currentBid) {
            if ($scope.currentBid.documents) {
                if ($scope.currentBid.documents) {
                    $scope.allBidDocuments = $scope.currentBid.documents;
                    $scope.eligibilityDocuments = $scope.currentBid.documents.filter(document => document.documentType === 'eligibilityDocuments');
                }
                if ($scope.currentBid.financialDocuments)
                    $scope.allBidDocuments = [...$scope.allBidDocuments, ...$scope.currentBid.financialDocuments];
                if ($scope.currentBid.eligibilityDocuments) {
                    $scope.allBidDocuments = [...$scope.allBidDocuments, ...$scope.currentBid.eligibilityDocuments];
                    $scope.eligibilityDocuments = [...$scope.eligibilityDocuments, ...$scope.currentBid.eligibilityDocuments];
                }
                if ($scope.currentBid.qualificationDocuments)
                    $scope.allBidDocuments = [...$scope.allBidDocuments, ...$scope.currentBid.qualificationDocuments];

                $scope.allBidDocuments = $filter('versionFilter')($scope.allBidDocuments);
            }
        }
    }


    initBidData();

    let stop;
    function stopInterval() {
        $scope.loadInProgress = false;
        if (angular.isDefined(stop)) {
            $interval.cancel(stop);
            stop = undefined;
            console.log('stop interval');
            if (!$scope.currentBid.meta) $state.reload(); //
        }
    }

    if ($scope.currentBid && $scope.publishStatus === 'pending') { //
        $scope.loadInProgress = true;
        let counter = 0;
        stop = $interval(() => {
            if ($state.current.name !== 'tenderView.ownBid' || $scope.publishStatus === 'failed') { //
                stopInterval();
                return;
            }
            console.log('progress', $scope.upload_progress, 'counter', counter);
            if ($scope.upload_progress < 100) {
                $scope.upload_progress = $scope.upload_progress < 80
                    ? +($scope.upload_progress + 0.2).toFixed(1)
                    : +($scope.upload_progress + 0.1).toFixed(1);
                if (counter < 10)
                    counter++;
                else {
                    tenderService.get($scope.tenderId)
                        .then(data => {
                            console.log('progress tenderService.get data', data);
                            if (data.bid) {
                                $scope.currentBid = data.bid;
                                initBidData();
                            } else {
                                console.log('progress tenderService response has no bid data');
                            }
                        })
                        .catch(error => {
                            console.log('progress bid publishing tenderService error', error);
                        })
                        .finally(() => {
                            counter = 0;
                        });
                }
            } else
                stopInterval();
        }, 1000);
    }

    if ($scope.currentBid && ['pending', 'active'].includes($scope.currentBid.status) && !$scope.currentBid.isSigned && $scope.actions.can_upload_bid_docs &&
        (($scope.tender.procurementMethodType === 'belowThreshold' && $scope.tender.procurementMethodRationale === 'simple')
          || !['belowThreshold', 'reporting', 'negotiation', 'negotiation.quick'].includes($scope.tender.procurementMethodType)
        ) && ['active.enquiries', 'active.tendering'].includes($scope.tender.status)
    ) {
        $timeout(() => {
            $uibModal.open({
                templateUrl: "/static/scripts/modules/TenderView/views/modals/confirm-modal.html",
                controller: "confirmModalCtrl",
                resolve: {
                    data: () => ({
                        title: gettext('З 12.04.2021 накладання КЕП є обов\'язковим!'),
                        description: gettext(`
                            В електронній системі закупівель було здійснено доопрацювання, а саме: обов'язковість
                            накладання УЕП/КЕП на пропозицію учасника в спрощеній закупівлі, відкритих торгах та
                            відкритих торгах з публікацією англійською мовою, рамкових угодах (1 та 2 етапи),
                            конкурентному діалозі (1 та 2 етапи), конкурентному діалозі з публікацією англійською
                            мовою (1 та 2 етапи), у закупівлях енергосервісу, спрощеній закупівлі із застосуванням
                            електронної системи.
                        `),
                        yesWord: gettext('Підписати'),
                        noWord: gettext('Пізніше')
                    })
                },
                backdrop: 'static'
            }).result.then(result => {
                if (result && result.confirm) $scope.signBid($scope.currentBid);
            });
        }, 1000);
    }

    $scope.publishBid = function () {
        $scope.loadInProgress = true;
        tenderBidService.edit($scope.tenderId, $scope.currentBid.id, { status: "pending" })
            .then(data => {
                console.log('publishBid tenderBidService.edit data', data);
                $timeout(() => {
                    $state.reload();
                }, 2000);
                ntFlash.success(gettext('Пропозиція успішно опублікована.'));
                billingService.loadCurrentWallet();
            })
            .finally(() => {
                $scope.loadInProgress = false;
            });
    };

    $scope.activateBid = function () {
        let status = 'pending';
        // if (['aboveThresholdEU',
        //      'competitiveDialogueUA',
        //      'competitiveDialogueEU',
        //      'competitiveDialogueEU.stage2',
        //      'esco',
        //      'closeFrameworkAgreementUA'].includes($scope.tender.procurementMethodType)
        // ) {
        //     status = 'pending';
        // }

        $scope.loadInProgress = true;
        tenderBidService.edit($scope.tenderId, $scope.currentBid.id, { status: status })
            .then(data => {
                console.log('activateBid tenderBidService.edit data', data);
                $timeout(() => {
                    $state.reload();
                }, 500);
                ntFlash.success(gettext('Пропозиція успішно активована.'));
            })
            .finally(() => {
                $scope.loadInProgress = false;
            });
    };

    function sortAddedFilesByCreateId(array) {
        let resultObj = {};
        for(let item of array) {
            let obj = {};
            obj.documentOf = item.documentOf;
            if (item.documentOf !== 'tender') obj.relatedItem = item.relatedItem;
            obj.documentType = item.documentType;
            if (item.confidentiality === 'buyerOnly') {
                obj.confidentiality = item.confidentiality;
                obj.confidentialityRationale = item.confidentialityRationale;
            }
            resultObj[item.createId] = obj;
        }
        return resultObj;
    }

    function getEnvelopeByDocumentType(documentType) {
        let envelope = 'documents';
        if (hasPrequalification()) {
            if (['commercialProposal', 'billOfQuantity', 'winningBid'].includes(documentType))
                envelope = 'financialDocuments';
            else if (documentType === 'eligibilityDocuments')
                envelope = 'eligibilityDocuments';
        }
        return envelope;
    }

    function hasPrequalification() {
        return [
            'aboveThresholdEU',
            'competitiveDialogueUA',
            'competitiveDialogueEU',
            'competitiveDialogueEU.stage2',
            'esco',
            'closeFrameworkAgreementUA'
        ].includes($scope.tender.procurementMethodType);
    }

    function prepareBid(currentBid, isCriteriaEditing) {
        const cleanBid = {};
        if (isCriteriaEditing) {
            cleanBid.requirementResponses = currentBid.requirementResponses;
            cleanBid.requirementResponses.forEach(response => {
                (response.evidences || []).forEach(evidence => {
                    if (evidence.type === 'statement') delete evidence.relatedDocument;
                });
            });
        } else {
            cleanBid.documents = currentBid.documents;
            if (hasPrequalification) {
                cleanBid.financialDocuments = currentBid.financialDocuments;
                cleanBid.eligibilityDocuments = currentBid.eligibilityDocuments;
                cleanBid.qualificationDocuments = currentBid.qualificationDocuments;
            }
            (hasPrequalification()
                ? ['documents', 'financialDocuments', 'eligibilityDocuments', 'qualificationDocuments']
                : ['documents']
            ).forEach(field => {
                for (let i = 0; i < (cleanBid[field] || []).length; i++) {
                    const el = cleanBid[field][i];
                    if (el.documentOf === 'tender')
                        delete el.relatedItem;
                    if (!el.confidentiality || el.confidentiality === 'public')
                        delete el.confidentialityRationale;
                    let envelope = getEnvelopeByDocumentType(el.documentType);
                    if (field !== envelope) {
                        cleanBid[field].splice(i, 1);
                        cleanBid[envelope].push(el);
                        i--;
                    }
                }
            });
        }
        return cleanBid;
    }

    $scope.saveDocumentsChanges = function() {
        // $scope.addedFiles = [];
        $scope.errorFiles = [];
        let tempFiles = $scope.addedFiles;
        const promises = [];
        if ($scope.addedFiles.length || $scope.ownDocumentsForm.$dirty) {
            $scope.loadInProgress = true;
            (tempFiles|| []).forEach((file, index) => {
                let type = ""
                if (['aboveThresholdEU',
                    // 'closeFrameworkAgreementUA',
                    'esco',
                    'competitiveDialogueEU',
                    'competitiveDialogueEU.stage2'
                ].includes($scope.tender.procurementMethodType)
                ) {
                    switch ( file.documentType ) {
                        case 'technicalSpecifications':
                            type = 'documents';
                            break;
                        case 'qualificationDocuments':
                            type = 'documents';
                            break;
                        case 'commercialProposal':
                            type = 'financialDocuments';
                            break;
                        case 'billOfQuantity':
                            type = 'financialDocuments';
                            break;
                        case 'eligibilityDocuments':
                            type = 'eligibilityDocuments';
                            break;
                        case 'evidence':
                            type = 'documents';
                            break;
                        case 'winningBid':
                            type = 'financialDocuments';
                            break;
                        default: type = 'documents';
                    }
                } else {
                    type = 'documents';
                }
                const fileData = {
                    documentOf: file.documentOf,
                    documentType: file.documentType,
                    confidentiality: file.confidentiality,
                    confidentialityRationale: file.confidentialityRationale,
                }
                if($scope.documentTypeAndConfidentialityScheme.buyerOnly){
                    delete fileData.confidentiality;
                    delete fileData.confidentialityRationale;
                }
                if(file.relatedItem != ''){
                    fileData.relatedItem = file.relatedItem;
                }
                promises.push(documentsService.saveDocsForBidDraft(file, $scope.tenderId, {
                    bidId: $scope.currentBid.id,
                    createId: file.createId,
                    type: type,
                    docData: fileData
                })
                    .then(data => {
                        console.log(data)
                        if (data.status === 200) {
                            $scope.addedFiles =  $scope.addedFiles.filter(el => el.createId !== data.createId)
                            isChanged = true;
                        } else {
                            if (data.createId) {
                                const fileItem = $scope.addedFiles.find(el => el.createId === data.createId);

                                if (fileItem) {
                                    $scope.errorFiles.push(fileItem)
                                    fileItem.error = true;
                                    $timeout(() => { delete fileItem.error; }, 10000);
                                }
                            }
                        }
                    return data;
                    })
                );
            });

            console.log('Added files', $scope.addedFiles);
            // $scope.addedFiles = [];
            // $scope.errorFiles = [];

            prepareBidDoc()
            let isChanged = $scope.ownDocumentsForm.$dirty;
            // return;
            Promise.all(promises)
                // .then(data => {
                //     console.log('saveDocumentsChanges documentsService data', data);
                //     let addedFilesAsObject = sortAddedFilesByCreateId($scope.addedFiles);
                //     // console.log(addedFilesAsObject)
                //     // let docObj;
                //
                //     data.forEach(item => {
                //         if (item.status === 200) {
                //
                //             isChanged = true;
                //
                //
                //             // if (item.createId && item.data && item.data.id) {
                //             //     // $scope.errorFiles = [...$scope.addedFiles.filter(el=>el.createId === item.createId)]
                //             //     // console.log($scope.errorFiles )
                //             //     //
                //             //     // console.log(item)
                //             //
                //             //
                //             //     // delete item.data.action;
                //             //     // docObj = {...item.data, ...addedFilesAsObject[item.createId]};
                //             //     // let type = getEnvelopeByDocumentType(docObj.documentType);
                //             //     // console.log(type)
                //             //     // TODO can be fix for document loading error
                //             //     // if($scope.currentBid[type]) {
                //             //     //     $scope.currentBid[type].push(docObj);
                //             //     // } else {
                //             //     //     $scope.currentBid[type] = []
                //             //     //     $scope.currentBid[type].push(docObj);
                //             //     // }
                //             //
                //             //     isChanged = true;
                //             // } else if (item.documentId) {
                //             //     // const i = $scope.currentBid[item.type].findIndex(doc => doc.id === item.documentId);
                //             //     // if (i !== -1) {
                //             //     //     $scope.currentBid[item.type].splice(i, 1);
                //             //         isChanged = true;
                //             //     // }
                //             // }
                //         } else {
                //             if (item.createId) {
                //                 const fileItem = $scope.addedFiles.find(el => el.createId === item.createId);
                //
                //                 $scope.errorFiles.push(fileItem)
                //                 if (fileItem) {
                //                     fileItem.error = true;
                //                     $timeout(() => { delete fileItem.error; }, 10000);
                //                 }
                //             }
                //             // else if (item.documentId) {
                //             //     const docItem = $scope.allBidDocuments.find(el => el.id === item.documentId);
                //             //     if (docItem) {
                //             //         docItem.error = true;
                //             //         $timeout(() => { delete docItem.error; }, 5000);
                //             //         if (item.status === 404) {
                //             //             const i = $scope.currentBid[item.type].findIndex(doc => doc.id === item.documentId);
                //             //             if (i !== -1) {
                //             //                 $scope.currentBid[item.type].splice(i, 1);
                //             //                 isChanged = true;
                //             //             }
                //             //         }
                //             //     }
                //             // }
                //             // 400 - error empty or big document
                //             // 403 - error permition denied
                //             // 404 - error document does not found
                //
                //         }
                //     });
                //     return data
                //
                //
                // })
                .then((data)=>{
                    if (isChanged) {
                       tenderBidService.get($scope.tenderId,$scope.currentBid.id)
                            .then(data => {
                                let response = data.response


                                if (response.documents) {
                                    $scope.allBidDocuments = [...response.documents];
                                    $scope.eligibilityDocuments = response.documents.filter(document => document.documentType === 'eligibilityDocuments');
                                }
                                if (response.financialDocuments)
                                    $scope.allBidDocuments = [...$scope.allBidDocuments, ...response.financialDocuments];
                                if (response.eligibilityDocuments) {
                                    $scope.allBidDocuments = [...$scope.allBidDocuments, ...response.eligibilityDocuments];
                                    $scope.eligibilityDocuments = [ ...response.eligibilityDocuments];
                                }
                                if (response.qualificationDocuments)
                                    $scope.allBidDocuments = [...$scope.allBidDocuments, ...response.qualificationDocuments];

                                $scope.allBidDocuments = $filter('versionFilter')($scope.allBidDocuments);
                                $scope.ownDocumentsForm.$dirty = false
                                $scope.ownDocumentsForm.$pristine = true
                                if($scope.addedFiles.length == 0){
                                    ntFlash.success(gettext('Зміни збережені'));
                                } else {
                                    let errString = '';
                                    $scope.addedFiles.forEach(el=>errString += ', ' + el.name)
                                    ntFlash.error( gettext( 'Ошибка загрузки документов: ' )+errString);
                                }

                            })
                            .catch(error => {
                                console.log('saveDocumentsChanges tenderBidService error', error);
                            });

                    }
                })
                .catch(error => {
                    console.log('saveDocumentsChanges documentsService error', error);
                })
                .finally(() => {
                    $scope.loadInProgress = false;

                });
        } else {
            ntFlash.error(gettext('Змін в перечні документів не зафіксовано'));
        }
    }

    $scope.changeDocData = function(change, document){
        let fileData = { }
        if(change == 'type'){
            fileData = {
                documentType : document.documentType
            }
        } else if(change == 'of'){

            if((document.documentOf == 'lot' || document.documentOf == 'item') &&
                (document.relatedItem && document.relatedItem != '')){
                fileData = {
                    documentOf : document.documentOf,
                    relatedItem: document.relatedItem
                }
            } else if (document.documentOf == "tender" ){

                fileData = {
                    documentOf : document.documentOf
                }
            }
        }
        documentsService.editBid($scope.tenderId, document.id, {
            bidId: $scope.currentBid.id,
        },
            fileData
        )
            .then((docs)=>{
                tenderBidService.get($scope.tenderId,$scope.currentBid.id)
                    .then(data => {
                        console.log(data)
                        data = data.response

                        if (data.documents) {
                            // $scope.allBidDocuments = $scope.currentBid.documents;
                            $scope.eligibilityDocuments = data.documents.filter(document => document.documentType === 'eligibilityDocuments');
                        }
                        $scope.ownDocumentsForm.$dirty = false
                        $scope.ownDocumentsForm.$pristine = true
                        ntFlash.success(gettext('Зміни збережені'));
                        console.log( $scope.eligibilityDocuments)
                    })
                    .catch(error => {
                        console.log('saveDocumentsChanges tenderBidService error', error);
                    });

            })
            .catch(error=>{
                    document.error = true;
                    $timeout(() => { delete document.error; }, 5000);
            });
    }
    $scope.changeRequirement = function(requirementGroup){
        console.log(requirementGroup)
    }



    $scope.saveCriteriaChanges = function() {


        if ($scope.ownCriteriaForm.$pristine) {
            ntFlash.error(gettext('Змін в Кваліфікаційних крітеріях не зафіксовано'));
            return;
        }
        let requirementResponses = [];
        $scope.criterias.forEach(criteria => {
            criteria.requirementGroups.forEach(group => {
                if (group.id === criteria.requirementGroupsSelected) {
                    group.requirements.forEach(requirement => {
                        if (requirement.requirementResponse) {
                            (requirement.requirementResponse.evidences || []).forEach(evidence => {
                                if (evidence.relatedDocument && evidence.relatedDocument.id)
                                    evidence.relatedDocument.title = $scope.titlesDictionary[evidence.relatedDocument.id];
                            });
                            requirementResponses.push(requirement.requirementResponse);
                        }
                    });
                }
            });
        });
        // if()
        // console.log($scope.currentBid)
        // $scope.currentBid.requirementResponses = requirementResponses;
        $scope.ownDocumentsForm.$dirty = false;
        // console.log(requirementResponses)

        // let requirementResponses = [];
        // cleanBid.requirementResponses.forEach(response => {
        //     (response.evidences || []).forEach(evidence => {
        //         if (evidence.type === 'statement') delete evidence.relatedDocument;
        //     });
        // });
        if($scope.currentBid.requirementResponses){
            tenderBidService.editCriteria($scope.tenderId, $scope.currentBid.id, requirementResponses)
                .then(data => {
                    console.log('saveCriteriaChanges tenderBidService data', data);

                    // $scope.currentBid.requirementResponses = requirementResponses;
                    $scope.ownCriteriaForm.$dirty = false
                    $scope.ownCriteriaForm.$pristine = true
                    ntFlash.success(gettext('Зміни збережені'));
                    // $timeout(() => {
                    //     $state.reload();
                    // }, 500);
                })
                .catch(error => {
                    console.log('saveCriteriaChanges tenderBidService error', error);
                });
        } else {

            tenderBidService.saveCriteria($scope.tenderId, $scope.currentBid.id, requirementResponses)
                .then(data => {
                    console.log('saveCriteriaChanges tenderBidService data', data);
                    // $timeout(() => {
                    //     $state.reload();
                    // }, 500);
                    $scope.ownCriteriaForm.$dirty = false
                    $scope.ownCriteriaForm.$pristine = true
                    $scope.currentBid.requirementResponses = requirementResponses;
                    ntFlash.success(gettext('Зміни збережені'));
                })
                .catch(error => {
                    console.log('saveCriteriaChanges tenderBidService error', error);
                });
        }

    }

    $scope.updateCriteriaChanges = function (criteria){
            // console.log(criteria)
        // let requirementResponses = [];
        //
        // $scope.criterias.forEach(criteria => {
        //     criteria.requirementGroups.forEach(group => {
        //         if (group.id === criteria.requirementGroupsSelected) {
        //             group.requirements.forEach(requirement => {
        //                 if (requirement.requirementResponse) {
        //                     (requirement.requirementResponse.evidences || []).forEach(evidence => {
        //                         if (evidence.relatedDocument && evidence.relatedDocument.id)
        //                             evidence.relatedDocument.title = $scope.titlesDictionary[evidence.relatedDocument.id];
        //                     });
        //                     requirementResponses.push(requirement.requirementResponse);
        //                 }
        //             });
        //         }
        //     });
        // });
        //
        // console.log(requirementResponses);
        //
        //
        // tenderBidService.editCriteria($scope.tenderId, $scope.currentBid.id, requirementResponses)
        //     .then(data => {
        //         console.log('editCriteriaChanges tenderBidService data', data);
        //         // $timeout(() => {
        //         //     $state.reload();
        //         // }, 500);
        //         ntFlash.success(gettext('Зміни збережені'));
        //     })
        //     .catch(error => {
        //         console.log('editCriteriaChanges tenderBidService error', error);
        //     });

    }


    $scope.deleteFileFromList = function(index) {
        $scope.addedFiles.splice(index, 1);
    };
    //
    // $scope.deleteDocFromBid = function(index) {
    //     if (!$scope.allBidDocuments[index].isDeleted) {
    //         $scope.deletedFiles.push($scope.allBidDocuments[index]);
    //         $scope.allBidDocuments[index].isDeleted = true;
    //     } else {
    //         const i = $scope.deletedFiles.findIndex(item => item.id === $scope.allBidDocuments[index].id);
    //         if (i !== -1) $scope.deletedFiles.splice(i, 1);
    //         $scope.allBidDocuments[index].isDeleted = false;
    //     }
    // }

    $scope.$watch('selected.files', function(val) {
        // console.log('Add file to form:', val)if ($scope.selected.files && $scope.selected.files.length) {
            val.forEach(function(el) {
                el.createId = md5.createHash(uuid4.generate());
                el.documentOf = $scope.loadDocOptionList.documentOf;
                el.relatedItem = $scope.loadDocOptionList.relatedItem;
                el.documentType = $scope.loadDocOptionList.documentType;
                el.confidentiality = $scope.loadDocOptionList.confidentiality;
                el.confidentialityRationale = $scope.loadDocOptionList.confidentialityRationale;
            });
            $scope.addedFiles = [...$scope.addedFiles, ...val];
            $scope.loadDocOptionList.confidentialityRationale = ''
            $scope.loadDocOptionList.confidentiality = 'public'
        });

    $scope.$watch('replace.files', function(val) {
        if ($scope.replace.files && $scope.replace.files.length) {
            $scope.loadInProgress = true;
            return $uibModal.open({
                templateUrl: "/static/scripts/modules/TenderView/views/modals/confirm-modal.html",
                controller: "confirmModalCtrl",
                resolve: {
                    data: () => ({
                        title: gettext('Заміна документа'),
                        description: gettext('Будь ласка, підтвердіть заміну документа'),
                        yesWord: gettext('Підтверджую'),
                        noWord: gettext('Назад')
                    })
                },
                backdrop: 'static'
            }).result.then(result => {
                if (!result) {
                    $scope.loadInProgress = false;
                } else if (result.confirm) {
                    documentsService.replace(val[0], $scope.tenderId, $scope.replaceDocumentId, {
                        bidId: $scope.currentBid.id,
                        documentsType: getEnvelopeByDocumentType($scope.documentType)
                    })
                        .then(function(data) {
                            console.log('replace.files documentsService.replace data', data);
                            $timeout(() => {
                                $state.reload();
                            }, 500);
                            ntFlash.success(gettext('Документ успешно заменен.'));
                        })
                        .catch((error) => {
                            console.log('replace.files documentsService.replace error', error);
                        })
                        .finally(() => {
                            $scope.loadInProgress = false;
                        });
                }
            });
        }
    });

    $scope.replaceBidDocument = function(documentId, documentType) {
        $scope.replaceDocumentId = documentId;
        $scope.documentType = documentType;
    }

    $scope.getTitleByValue = function(value) {
        const values = {
            draft: gettext('Чернетка'),
            local_draft: gettext('Чернетка'),
            active: gettext('Активна'),
            invalid: gettext('Не валідна'),
            deleted: gettext('Видалена'),
            pending: gettext('Активна, чекає на прекваліфікацію'),
            'invalid.pre-qualification': gettext('Не валідна'),
            tender: gettext('Закупка'),
            lot: gettext('Лот'),
            item: gettext('Позиція')
        };
        return values[value] || value;
    }

    $scope.sortDirections = {
        title: true,
        datePublished: true,
        documentType: true,
        documentOf: true,
        relatedItem: true
    };

    $scope.sortCurrentBidDocuments = function(properties) {
        $scope.allBidDocuments.sort(sortFunc(properties));
        properties.forEach(prop => {
            $scope.sortDirections[prop] = !$scope.sortDirections[prop];
        });
    }
    $scope.sortCurrentBidDocuments(['title']);

    function sortFunc(properties) {
        return function(a, b) {
            if (properties.length == 0) return 0;
            property = properties[0];
            let sortA = property === 'relatedItem'
                            ? $scope.titlesDictionary[a.relatedItem]
                            : property === 'documentType'
                                ? $filter('documentTypeCaption')(a.documentType)
                                : a[property];
            let sortB = property === 'relatedItem'
                            ? $scope.titlesDictionary[b.relatedItem]
                            : property === 'documentType'
                                ? $filter('documentTypeCaption')(b.documentType)
                                : b[property];
            return ($scope.sortDirections[property] ? sortA > sortB : sortB > sortA) ? 1
                : ($scope.sortDirections[property] ? sortA < sortB : sortB < sortA) ? -1
                    : sortFunc(properties.slice(1))(a, b);
        }
    }

    $scope.uploadDocument = function() {
        return $uibModal.open({
            templateUrl: "/static/scripts/modules/TenderView/views/modals/tender-view.documents.upload.modal.html",
            controller: "tenderViewDocumentsUploadModalCtrl",
            resolve: {
                data: function() {
                    return {
                        tenderId: $scope.tender.id,
                        bidId: $scope.currentBid.id,
                        lots: $scope.tender.lots,
                        items: $scope.tender.items,
                        procurementMethodType: $scope.tender.procurementMethodType,
                        tenderStatus: $scope.tender.status,
                        actions: $scope.actions,
                        isEsco: $scope.tender.procurementMethodType === 'esco',
                        lotValues: $scope.currentBid.lotValues,
                        isMilestones24h: $scope.isMilestones24h,
                        isMilestonesAlp: $scope.isMilestonesAlp
                    };
                }
            },
            backdrop: 'static'
        }).result.then(() => {
            $timeout(() => {
                $state.reload();
            }, 500);
        });
    }

    $scope.selectAllValueCheckboxes = function() {

        $scope.criterias.forEach(criteria => {
            criteria.requirementGroups.forEach(group => {
                if (criteria.requirementGroupsSelected === group.id &&
                    criteria.classification.id !== 'CRITERION.EXCLUSION.CONVICTIONS.PARTICIPATION_IN_CRIMINAL_ORGANISATION') {
                    group.requirements.forEach(requirement => {
                        if (requirement.dataType === 'boolean' && requirement.requirementResponse)
                            requirement.requirementResponse.value = true;
                    });
                }
            });
        });
        $scope.ownCriteriaForm.$pristine = false
        $scope.ownCriteriaForm.$dirty= true
    }

    $scope.getFormatDocTitle = function(docTitle) {
        const nameArr = docTitle.split('.');
        let title = '';
        let extension = nameArr[nameArr.length - 1]
        nameArr.pop()
        title = nameArr.join('.');
        return `<span class="name">${title}</span><span class="extension">.${extension}</span>`;
    }

    $scope.docSign = false


    $scope.addSign = () => {

        if ($scope.signDocArr.length < 1 && $scope.signDocUrlArr.length < 1) {
            ntFlash.error(gettext('Нет документов на подпись'));
            return;
        }

        $scope.loadElement = document.createElement('div');
        $scope.loadElement.setAttribute('id', 'loading-bar-spinner')
        let spinner = document.createElement('div')
        spinner.classList.add('spinner-icon')
        $scope.loadElement.appendChild(spinner)
        document.body.appendChild($scope.loadElement)

        $scope.loadInProgress = true;

        if($scope.signDocUrlArr.length ){
            $scope.checkDoc()
                .then((files)=>{
                    console.log(files)
                    $scope.signDocArr = [...$scope.signDocArr, ...files]
                })
                .then(()=>{

                    setTimeout(()=>{
                        $scope.loadElement.remove()
                    },30000)
                    return $scope.SignDocs($scope.signDocArr);
                })
                .finally(()=>{

                    $scope.loadInProgress = false
                    ntFlash.success(gettext('Документы подписаны и добавлены.'));
                })

        } else {
            $scope.loadElement.remove()
            $scope.SignDocs($scope.signDocArr)
                .finally(()=>{
                    $scope.loadInProgress = false;
                    ntFlash.success(gettext('Документы подписаны и добавлены.'));
                })
        }

    }

    $scope.SignDocs = (files)=>{


        return documentsService.sign(files, {
            reload: false,
            setSign : true,
            checkCompany: true
        })
            .then(data => {
                console.log('documentsService.sign docs', data)
                console.log('files', files)
                $scope.$apply(()=>{
                    (data || []).map((el, index)=>{
                        el.createId = md5.createHash(uuid4.generate());
                        el.documentOf = files[index].documentOf;
                        el.relatedItem = files[index].relatedItem;
                        el.documentType = files[index].documentType;
                        el.confidentiality =  files[index].confidentiality;
                        el.confidentialityRationale = files[index].confidentialityRationale;
                        $scope.selected.files.push(el)
                        $scope.addedFiles.push(el)
                        return el;
                    })
                    $scope.$emit('selected.files', data );
                    $scope.signDocArr = []
                })


            })
            .catch(error => console.log('documentsService.sign error', error));
    }

    $scope.checkDoc = () => {
        function download(url, filedata) {
            return fetch(url)
                .then(response => response.body)
                .then(rb => {
                    const reader = rb.getReader();
                    return new ReadableStream({
                        start(controller) {
                            function push() {
                                reader.read().then( ({done, value}) => {
                                    if (done) {
                                        controller.close();
                                        return;
                                    }
                                    controller.enqueue(value);
                                    push();
                                })
                            }
                            push();
                        }
                    });
                })
                .then(stream => {
                    // Respond with our stream
                    return new Response(stream, { headers: { "Content-Type": "text/html" } });
                })
                .then(result => {
                    let signBlob = new Blob([result]);
                    let file = new File(
                        [signBlob],
                        `${filedata.title}`,
                    )
                    file.createId = md5.createHash(uuid4.generate());
                    file.documentOf = filedata.documentOf;
                    file.relatedItem = filedata.relatedItem;
                    file.documentType = filedata.documentType;
                    file.confidentiality =  filedata.confidentiality;
                    file.confidentialityRationale = filedata.confidentialityRationale;

                    return file;
                });
        }

        let promises = [];
        // console.log($scope.signDocUrlArr)


        $scope.signDocUrlArr.map((el)=>{
            console.log(el)
            let originURL = $scope.isBidDraft ? `${el.url}?view=proxy`: `/opc/proxy_doc_download/?doc_url=${encodeURIComponent(el.url)}&view=proxy`;
            promises.push(download(originURL,el));
        })

        return Promise.all(promises)
            .then((files)=>{
                console.log(files)
                return files;
            })

    }

    $scope.changeSign = ()=>{
        $scope.docSign = !$scope.docSign
        $scope.signDocArr = [];
        $scope.signDocUrlArr = [];
    }

    $scope.optionLoad = ()=>{
        $scope.loadDocOption =! $scope.loadDocOption
        // $scope.loadDocOptionList
    }
    $scope.changeDocOption = (type, value)=>{


    }

    $scope.selectDoc = ($event)=>{
        console.log($event)
    }

    $scope.chooseAllDoc = () =>{

        $scope.signDocArr = [...$scope.addedFiles]
        $scope.signDocUrlArr = [...$scope.allBidDocuments]
    }

    $scope.signDocArr = [];
    $scope.signDocUrlArr = [];
    $scope.addDocsToSign = (document, file= false)=>{

        let idx = $scope.signDocArr.indexOf(document);

        if (idx > -1) {
            $scope.signDocArr.splice(idx, 1);
        } else {
            $scope.signDocArr.push(document);
        }
    }

    $scope.addDocsUrlToSign = (document)=>{

        let idx = $scope.signDocUrlArr.indexOf(document);

        if (idx > -1) {
            $scope.signDocUrlArr.splice(idx, 1);
        } else {
            $scope.signDocUrlArr.push(document);
        }


    }


    $scope.countDocument = ()=>{
       return $scope.allBidDocuments.length + $scope.addedFiles.length
    }

    $scope.checkCriteria=(id)=>{
        return ['CRITERION.OTHER.CONTRACT.GUARANTEE','CRITERION.OTHER.BID.GUARANTEE'].includes(id)
    }

});
