tendersList.factory('tendersSearchService', function (
    http
) {
    var searchResource = 'tender/?:pageNum:query:status:tendersOnlyOf:procurementMethodType:cpv:dkpp_inn:region:amount_gte:amount_lte:createReport:funders:fund_name:scpv:i:cs:create_gte:create_lte:tp_gte:tp_lte:ap_gte:ap_lte:edrpou_buyer:edrpou_supplier:edrpou:covid:bookmark:city';

    return {
        get: function (data) {
            var dkpp_inn = (data.dkpp ? data.dkpp : '') + (data.dkpp && data.inn ? ',' : '') + (data.inn ? data.inn : '');
            var query = searchResource
                .replace(/:query/g, (data.query ? '&q=' + encodeURIComponent(data.query) : ''))
                .replace(/:status/g, (data.status ? '&s=' + data.status : ''))
                .replace(/:tendersOnlyOf/g, (data.tendersOnlyOf ? `&${data.tendersOnlyOf}=1` : ''))
                .replace(/:procurementMethodType/g, (data.procurementMethodType ? '&m=' + data.procurementMethodType : ''))
                .replace(/:cpv/g, (data.cpv ? '&c=' + data.cpv : ''))
                .replace(/:dkpp_inn/g, (dkpp_inn ? '&d=' + dkpp_inn : ''))
                .replace(/:pageNum/g, (data.pageNum ? 'page=' + data.pageNum : 'page=1'))
                .replace(/:region/g, (data.region ? '&region=' + data.region : ''))
                .replace(/:amount_gte/g, (data.amount_gte ? '&amount_gte=' + data.amount_gte : ''))
                .replace(/:amount_lte/g, (data.amount_lte ? '&amount_lte=' + data.amount_lte : ''))
                .replace(/:createReport/g, (data.createReport ? '&createReport=' + data.createReport : ''))
                .replace(/:funders/g, (data.funders ? '&funders=' + data.funders : ''))
                .replace(/:fund_name/g, (data.fund_name ? '&fund_name=' + data.fund_name : ''))
                .replace(/:scpv/g, (data.scpv ? '&scpv=' + data.scpv : ''))
                .replace(/:i/g, (data.i ? '&i=' + data.i : ''))
                .replace(/:create_gte/g, (data.create_gte ? '&create_gte=' + data.create_gte : ''))
                .replace(/:create_lte/g, (data.create_lte ? '&create_lte=' + data.create_lte : ''))
                .replace(/:tp_gte/g, (data.tp_gte ? '&tp_gte=' + data.tp_gte : ''))
                .replace(/:tp_lte/g, (data.tp_lte ? '&tp_lte=' + data.tp_lte : ''))
                .replace(/:ap_gte/g, (data.ap_gte ? '&ap_gte=' + data.ap_gte : ''))
                .replace(/:ap_lte/g, (data.ap_lte ? '&ap_lte=' + data.ap_lte : ''))
                .replace(/:cs/g, (data.cs ? '&cs=' + data.cs : ''))
                .replace(/:edrpou_buyer/g, (data.edrpou_buyer ? '&edrpou_buyer=' + data.edrpou_buyer : ''))
                .replace(/:edrpou_supplier/g, (data.edrpou_supplier ? '&edrpou_supplier=' + data.edrpou_supplier : ''))
                .replace(/:edrpou/g, (data.edrpou ? '&edrpou=' + data.edrpou : ''))
                .replace(/:covid/g, (data.covid ? '&covid=' + data.covid : ''))
                .replace(/:bookmark/g, (data.bookmark ? '&bookmark=' + data.bookmark : ''))
                .replace(/:city/g, (data.city ? '&city=' + data.city : ''));

            query += '&s_not=local_draft';
            return http.get(query)
                .then(function (result) {
                    return result.data.response;
                });
        }
    };
});
