tenderView.controller('tenderViewOverviewCtrl', function (
    $scope,
    $rootScope,
    $state,
    $filter,
    $timeout,
    tenderService,
    lowConsultationService,
    transferService,
    tenderData,
    $uibModal,
    ntFlash,
    formationService,
    tenderPeriodsListService,
    guaranteeService
) {
    ntFlash.clean();

    $scope.tenderPeriods = tenderPeriodsListService.getList();

    $scope.currentTime = new Date();
    $scope.transfer = tenderData.transfer;
    $scope.tender = tenderData.data;
    $scope.actions = tenderData.action;
    $scope.relatedPlans = tenderData.plans;
    $scope.fullCriteria = false
    $scope.lots = tenderData.data.lots || [{
        empty: true
    }];
    $scope.titlesDict = {};

    if ($scope.tender.procurementMethodType === 'belowThreshold' && !$scope.tender.procurementMethodRationale) 
        $scope.tender.procurementMethodRationale = '';

    console.log('tenderData', tenderData);

    // $scope.milestones = tenderData.milestones || [];
    $scope.isMultilotTender = tenderData.data.lots && tenderData.data.lots.length ? true : false;
    $scope.config =  (tenderData.config) ? tenderData.config : null
    $scope.cancellations = $scope.tender.cancellations && $scope.tender.cancellations.length ? $scope.tender.cancellations : [];
    ($scope.tender.lots || []).forEach(function(lot) {
        lot.cancellations = [];
        $scope.cancellations.forEach(function(cancellation) {
            if (cancellation.relatedLot === lot.id && ['active', 'pending'].includes(cancellation.status)) {
                lot.cancellations.push(cancellation);
            }
        });
        $scope.titlesDict[lot.id] = lot.title;
    });

    $scope.isCompetitiveDialogue = tenderService.isCompetitiveDialogue($scope.tender);
    $scope.isCompetitiveDialogueStage2 = tenderService.isCompetitiveDialogue2Stage($scope.tender);

    $scope.isCanCreateCopy = $scope.actions.tender_owner && !$scope.isCompetitiveDialogueStage2;

    $scope.transferTokenOfNewTender = null;

    $scope.sendTransferToken = function(token) {
        if (token) {
            transferService.send('tender', token, $scope.tender.id)
                .then(function(data) {
                    console.log('response transferService', data);
                    if (data.data.response.action.tender_owner) {
                        ntFlash.success(gettext('Ваш запит на перенос виконано'));
                        $timeout(function () {
                            console.log('reload ...');
                            $state.reload();
                        }, 2000);
                    } else {
                        ntFlash.error(gettext('При спробі переносу виникла помилка'));
                    }
                })
                .catch(function(error) {
                    console.log('error', error);
                    ntFlash.error(error.errorMessages);
                })
        }
    }

    $scope.isTransferAllowed = true;

    if ($scope.isTransferAllowed) {
        const forbiddenStatuses = new Map([
            ['belowThreshold', ['complete', 'cancelled', 'unsuccessful']],
            ['reporting', ['complete', 'cancelled']],
            ['aboveThresholdUA', ['complete', 'cancelled', 'unsuccessful']],
            ['aboveThresholdEU', ['complete', 'unsuccessful', 'cancelled']],
            ['negotiation', ['complete', 'cancelled']],
            ['negotiation.quick', ['complete', 'cancelled']],
            ['aboveThresholdUA.defense', ['complete', 'unsuccessful', 'cancelled']],
            ['simple.defense', ['complete', 'unsuccessful', 'cancelled']],
            ['competitiveDialogueUA', ['active.stage2.waiting', 'complete', 'unsuccessful', 'cancelled']],
            ['competitiveDialogueUA.stage2', ['complete', 'unsuccessful', 'cancelled']],
            ['competitiveDialogueEU', ['active.stage2.waiting', 'complete', 'unsuccessful', 'cancelled']],
            ['competitiveDialogueEU.stage2', ['complete', 'unsuccessful', 'cancelled']],
            ['esco', ['complete', 'unsuccessful', 'cancelled']],
            ['closeFrameworkAgreementUA', ['complete', 'unsuccessful', 'cancelled']],
            ['closeFrameworkAgreementSelectionUA', ['draft.pending', 'draft.unsuccessful', 'complete', 'unsuccessful', 'cancelled']]
        ]);
    
        for (const type of forbiddenStatuses) {
            if (type[0] === $scope.tender.procurementMethodType) {
                for (const forbiddenStatus of type[1]) {
                    if (forbiddenStatus === $scope.tender.status) $scope.isTransferAllowed = false;
                }
                break;
            }
        }
    }

    // const signBidSplitedValue = [
    //     'aboveThresholdEU',
    //     'competitiveDialogueUA',
    //     'competitiveDialogueEU',
    //     'competitiveDialogueEU.stage2',
    //     'esco',
    //     'closeFrameworkAgreementUA',
    // ];
    //
    // if ($scope.tender.documents && $scope.tender.documents.length) {
    //     let currentSign
    //     $scope.tender.documents.forEach(function (document) {
    //         if (document.title === 'sign.p7s' && document.format === 'application/pkcs7-signature') {
    //             currentSign = document
    //             $scope.isSigned = true;
    //             $scope.dateSigned = document.dateModified;
    //         }
    //     });
    //     if(currentSign){
    //         let dateModifySign = new Date(currentSign.dateModified).getTime()
    //         let docModify = new Date($scope.tender.dateModified).getTime()
    //         if(dateModifySign < docModify){
    //             $scope.isSigned = false;
    //         }
    //     }
    // }
    //
    //
    // if (signBidSplitedValue.includes($scope.tender.procurementMethodType)
    //     && !$scope.isSigned
    //     && $scope.actions.tender_owner
    //     && $scope.tender.status !== 'local_draft'
    // ) {
    //
    //     $timeout(() => {
    //
    //         $uibModal.open({
    //             templateUrl: "/static/scripts/modules/TenderView/views/modals/confirm-modal.html",
    //             controller: "confirmModalCtrl",
    //             resolve: {
    //                 data: () => ({
    //                     title: gettext('З 12.04.2021 накладання КЕП є обов\'язковим!'),
    //                     description: gettext(`
    //                         В електронній системі закупівель було здійснено доопрацювання, а саме: обов'язковість
    //                         накладання УЕП/КЕП на пропозицію учасника в спрощеній закупівлі, відкритих торгах та
    //                         відкритих торгах з публікацією англійською мовою, рамкових угодах (1 та 2 етапи),
    //                         конкурентному діалозі (1 та 2 етапи), конкурентному діалозі з публікацією англійською
    //                         мовою (1 та 2 етапи), у закупівлях енергосервісу, спрощеній закупівлі із застосуванням
    //                         електронної системи.
    //                     `),
    //                     yesWord: gettext('Підписати'),
    //                     noWord: gettext('Пізніше')
    //                 })
    //             },
    //             backdrop: 'static'
    //         }).result.then(result => {
    //             if (result && result.confirm) $scope.sign();
    //         });
    //     }, 2000);
    // }


    // костыль, переделать через tenderService
    if ( $scope.tender.procurementMethodType == 'closeFrameworkAgreementSelectionUA' ) {
        $scope.tenderPeriods[0].label = gettext('Період запрошення');
    }

    var hasTimeToRequestGuarantee = false;
    if ($scope.tender.tenderPeriod && $scope.tender.tenderPeriod.endDate) {
        var dateEndPeriod = new Date($scope.tender.tenderPeriod.endDate),
            dateNow = new Date(),
            daysToEndEnquiry = (dateEndPeriod - dateNow) / 1000 / 60 / 60 / 24;
        hasTimeToRequestGuarantee = daysToEndEnquiry >= 1;
    }

    $scope.canRequestGuarantee = !$scope.actions.tender_owner && hasTimeToRequestGuarantee;

    $scope.goToTender = (tenderId) => {
        $state.go('tenderView', {
            id: tenderId
        });
    };

    $scope.tender.items.forEach(function (item) {
        if (item.deliveryAddress) {
            item.deliveryAddress.field = formationService.address(item.deliveryAddress);
        }
    });

    if (!$rootScope.isAnonymous) {
        if ($scope.tender.lots) {
            $scope.tender.lots.forEach(function (lot) {
                if (lot.guarantee && lot.guarantee.amount > 0) {
                    guaranteeService.get($scope.tender.id, lot.id)
                        .then((data) => {
                            if (data.status == 200) {
                                lot.hasBankGuaranteeRequest = true;
                            }
                        });
                }
            });
        } else {
            if ($scope.tender.guarantee && $scope.tender.guarantee.amount > 0) {
                guaranteeService.get($scope.tender.id)
                    .then((data) => {
                        if (data.status == 200) {
                            $scope.tender.hasBankGuaranteeRequest = true;
                        }
                    });
            }
        }
    }

    $scope.getFeatureOf = function (index) {
        if ($scope.tender.features && $scope.tender.features.length) {
            var feature = $scope.tender.features[index];

            if (feature && feature.featureOf === "tenderer") {
                return "tenderer";
            } else {
                for (let i = 0; i < $scope.tender.items.length; i++) {
                    if ($scope.tender.items[i].id === feature.relatedItem) {
                        return $scope.tender.items[i].description;
                    }
                }
                for (let i = 0; i < $scope.tender.lots.length; i++) {
                    if ($scope.tender.lots[i].id === feature.relatedItem) {
                        return $scope.tender.lots[i].title;
                    }
                }
                return "item";
            }
        }
    };

    $scope.getItemsByLot = (lot) => {
        return $scope.tender.items && $scope.tender.items.filter(item => item.relatedLot === lot.id);
    };

    $scope.getLotByItem = (item) => {
        return item && $scope.tender.lots && $scope.tender.lots.filter(lot => lot.id === item.relatedLot)[0];
    };

    $scope.getFeatures = (featureOf, relatedItem) => {
        return $scope.tender.features && $scope.tender.features.filter((feature) => {
            return feature.featureOf === featureOf && feature.relatedItem === relatedItem.id;
        });
    };

    $scope.isDateInPeriod = (date, from, to) => {
        return to ? date > (new Date(from)) && date < (new Date(to)) : date > (new Date(from));
    };

    $scope.isDatePassed = (date, to) => {
        return to && date > (new Date(to));
    };

    // $scope.createCopy = (tender) => {
    //     // $state.go('editTender', {
    //     //     lots: tender.lots ? "multilot" : "singlelot",
    //     //     tenderProcedure: tender.procurementMethodType,
    //     //     entity: 'tender',
    //     //     id: tender.id
    //     // });
    //
    //     console.log(tender)
    //     console.log("tenderProcedure", tender.procurementMethodType)
    //         console.log("lots", tender.lots ? "multilot" : "singlelot")
    //     console.log("entity", tender.plans ? "plan" : "tender")
    //     console.log("id", tender.plans ? tender.plans[0].id : tender.id)
    //
    //
    //     $state.go('editTender', {
    //         tenderProcedure: tender.procurementMethodType,
    //         lots: tender.lots ? "multilot" : "singlelot",
    //         entity: tender.plans ? "plan" : "tender",
    //         id: tender.plans ? tender.plans[0].id : tender.id
    //     });
    //
    // };



    $scope.getCriteriaToText = (criteria) => {
        // console.log(JSON.stringify(criteria));
        return JSON.stringify(criteria)
    }

    $scope.addGuarantee = (lot) => {
        var isAccepted = status === 'active';
        return $uibModal.open({
            templateUrl: "/static/scripts/modules/TenderView/views/modals/tender-view.guarantee.modal.html",
            controller: "tenderViewGuaranteeModalCtrl",
            resolve: {
                modalData: function () {
                    return {
                        tenderId: $scope.tender.id,
                        lotId: lot ? lot.id : null,
                        amount: lot ? lot.guarantee.amount : $scope.tender.guarantee.amount,
                        currency: lot ? lot.guarantee.currency : $scope.tender.guarantee.currency
                    };
                }
            },
            backdrop: 'false'
        }).result.then(function () {
            $timeout(function () {
                $state.reload();
            }, 2000);
        });
    };

    $scope.lawConsultation = (lot) => {
        lowConsultationService.get(tenderData.data.id, lot ? lot && lot.id : null).then((data)=>{
            if (data.status===204) {
                return $uibModal.open({
                    templateUrl: "/static/scripts/modules/TenderView/views/modals/tender-view.law-consultation.modal.html",
                    controller: "tenderViewLawConsultationModalCtrl",
                    resolve: {
                        modalData: function () {
                            return {
                                tenderId: $scope.tender.id,
                                lotId: lot ? lot.id : null,
                            };
                        }
                    },
                    backdrop: 'false'
                }).result.then(function () {
                    $timeout(function () {
                        $state.reload();
                    }, 2000);
                });
            } else if (data.status===200) {
                ntFlash.success(gettext('Ваш запит вже в роботі'));
            }
        });
    };

    $scope.makeComplaint = function(cancellationId) {
        return $uibModal.open({
            templateUrl: "/static/scripts/modules/TenderView/views/modals/tender-view.complaint.modal.html",
            controller: "tenderViewComplaintModalCtrl",
            resolve: {
                data: function() {
                    return {
                        tenderId: $scope.tender.id,
                        cancellationId: cancellationId,
                        isQuestion: true,
                        isMultilotTender: false,
                        isBelowThreshold: $scope.tender.procurementMethodType === 'belowThreshold'
                    }
                }
            },
            backdrop: 'static'
        })
    };
});
